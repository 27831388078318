import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import type { TenantRegistrationStatus } from "../../../../types/registration";
import type { TenantRegistrationActions } from "../../../../types/registration/actions";

export interface RegistrationListItemActionsMenuProps {
  status: TenantRegistrationStatus;
  onActionClick: (action: TenantRegistrationActions) => void;
}

export const RegistrationListItemActionsMenu: React.FC<
  RegistrationListItemActionsMenuProps
> = (props) => {
  return (
    <>
      <Menu as="div" className="flex justify-end">
        <div onClick={(event) => event.stopPropagation()}>
          <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-orange-900 dark:text-orange-100 bg-orange-100 dark:bg-orange-900 border border-transparent rounded-md hover:bg-orange-200 dark:hover:bg-orange-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            onClick={(event: React.MouseEvent) => event.stopPropagation()}
            static
            className="absolute right-12 mt-12 origin-top-right bg-white dark:bg-slate-800 divide-y divide-slate-100 dark:divide-slate-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {props.status === 'draft' && (
              <div className="px-1 py-1 flex flex-col">
                <Menu.Item>
                  <button
                    onClick={() => props.onActionClick('draft.edit')}
                    className="inline-flex justify-start w-full px-4 py-2 text-sm font-medium text-orange-900 dark:text-orange-100 bg-white dark:bg-slate-800 border border-transparent rounded-md hover:bg-orange-200 dark:hover:bg-orange-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Edit
                  </button>
                </Menu.Item>
                {/* <Menu.Item>
                  <button
                    onClick={() => props.onActionClick('draft.send-invite')}
                    className="inline-flex justify-start w-full px-4 py-2 text-sm font-medium text-orange-900 bg-white border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Send invitation
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => props.onActionClick('draft.delete')}
                    className="inline-flex justify-start w-full px-4 py-2 text-sm font-medium text-orange-900 bg-white border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Delete
                  </button>
                </Menu.Item> */}
              </div>
            )}
            {/* {props.status === 'invited' && (
              <div className="px-1 py-1 flex flex-col">
                <Menu.Item>
                  <button
                    onClick={() => props.onActionClick('invited.resend-invite')}
                    className="inline-flex justify-start w-full px-4 py-2 text-sm font-medium text-orange-900 bg-white border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Resend invitation
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => props.onActionClick('invited.delete')}
                    className="inline-flex justify-start w-full px-4 py-2 text-sm font-medium text-orange-900 bg-white border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Delete
                  </button>
                </Menu.Item>
              </div>
            )}
            {props.status === 'active' && (
              <div className="px-1 py-1 flex flex-col">
                <Menu.Item>
                  <button
                    onClick={() => props.onActionClick('active.archive')}
                    className="inline-flex justify-start w-full px-4 py-2 text-sm font-medium text-orange-900 bg-white border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                  >
                    Archive
                  </button>
                </Menu.Item>
              </div>
            )} */}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
};

export default RegistrationListItemActionsMenu;
