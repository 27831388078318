import React from 'react'

export interface LayoutProps {
  title: string
  children: any
}

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none">
      <div className="py-6">
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <div className="text-2xl font-medium text-gray-900 dark:text-gray-100">{props.title}</div>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8">{props.children}</div>
      </div>
    </main>
  )
}

export default Layout
