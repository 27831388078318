import React from "react";
import classnames from "classnames";

export interface PropTypes {
  size?: 1 | 2 | 3 | 4 | 5
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  color?: 'indigo' | 'emerald' | 'red' | 'slate' | 'orange'
  onClick?: any
  tabIndex?: number
  children?: React.ReactNode
}

export const Button: React.FC<PropTypes> = (props) => {
  const {
    size = 3,
    className,
    disabled = false,
    type = "button",
    color = "indigo",
    children,
    ...rest
  } = props;

  return (
    <button
      type={type}
      disabled={disabled}
      className={classnames(
        'border-transparent font-medium rounded-md',
        `text-${color}-600 dark:text-${color}-400`,
        `bg-${color}-100 dark:bg-${color}-900`,
        `hover:bg-${color}-200 dark:hover:bg-${color}-800`,
        `focus:ring-${color}-500`,
        'focus:outline-none focus:ring-2 focus:ring-offset-2',
        {
          'px-2.5 py-1.5 text-xs': size === 1,
          'px-3 py-2 text-sm': size === 2 || size === 3,
          'px-4 py-2 text-base': size === 4,
          'px-6 py-3 text-base': size === 5,
          'opacity-50 cursor-default pointer-events-none': disabled,
        },
        className
      )}
      {...rest}
    >
      {children}
    </button>
  )
};

export default Button;
