import React from "react";

import { usersAPI } from "../../shared/api";

import { format } from "date-fns";

import { UserStatusBadge } from "../../shared/components/UserStatusBadge";

import Select from "react-select";
import { createCustomSelectStyles } from "../../../components/utils/select-styles";

import type { User, Role } from "../../shared/types/models";

export interface UserDetailsProps {
  user: User;
  roles: Role[];
}

export const UserDetails: React.FC<UserDetailsProps> = (props) => {
  const [assignRolesToUser] =
    usersAPI.endpoints.assignRolesToUser.useMutation();
  const [unassignRolesFromUser] =
    usersAPI.endpoints.unassignRolesFromUser.useMutation();

  const [isAssigningRolesToUser, setIsAssigningRolesToUser] =
    React.useState<boolean>(false);
  const [roleIDsBeingRemoved, setRoleIDsBeingRemoved] = React.useState<
    Set<string>
  >(new Set());

  const userRoleIDs = React.useMemo<Set<string>>(
    () => new Set(props.user.roles.map((role) => role.id)),
    [props.user]
  );
  const roles = React.useMemo<Role[]>(
    () => props.roles.filter((role) => !userRoleIDs.has(role.id)),
    [userRoleIDs, props.roles]
  );

  const [selectedRoles, setSelectedRoles] = React.useState<Role[]>([]);

  const rolesSelectStyles = React.useMemo(
    () => createCustomSelectStyles<Role>(),
    []
  );

  async function handleAssignRolesButtonClick(): Promise<void> {
    if (selectedRoles.length === 0) {
      return;
    }

    setIsAssigningRolesToUser(true);

    const result = await assignRolesToUser({
      userID: props.user.id,
      roleIDs: selectedRoles.map((role) => role.id),
    });

    if ("data" in result) {
      setSelectedRoles([]);
    }

    setIsAssigningRolesToUser(false);
  }

  async function handleRemoveRoleButtonClick(role: Role): Promise<void> {
    setRoleIDsBeingRemoved((roleIDs) => {
      roleIDs.add(role.id);

      return new Set(roleIDs);
    });

    await unassignRolesFromUser({
      userID: props.user.id,
      roleIDs: [role.id],
    });

    setRoleIDsBeingRemoved((roleIDs) => {
      roleIDs.delete(role.id);

      return new Set(roleIDs);
    });
  }

  return (
    <dl className="divide-y divide-gray-200 dark:divide-gray-700">
      <div className="py-5 grid grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Full name</dt>
        <dd className="col-span-2">
          <div className="flex items-baseline">
            <span className="mr-2 text-sm text-gray-700 dark:text-gray-300">{`${props.user.firstName} ${props.user.lastName}`}</span>
            <UserStatusBadge status={props.user.status} />
          </div>
        </dd>
      </div>
      <div className="py-5 grid grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Email address</dt>
        <dd className="col-span-2 text-sm text-gray-700 dark:text-gray-300">{props.user.emailAddress}</dd>
      </div>
      <div className="py-5 grid grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Last login</dt>
        <dd className="col-span-2 text-sm text-gray-700 dark:text-gray-300">
          {props.user.lastLogin !== null ? (
            <span className="text-sm text-gray-700 dark:text-gray-300">
              {format(new Date(props.user.lastLogin), 'MMM d, yyyy')}
              <span className="text-sm text-gray-500 dark:text-gray-400">{` • ${format(new Date(props.user.lastLogin), 'h:mm aa')}`}</span>
            </span>
          ) : (
            <span className="text-sm text-gray-700 dark:text-gray-300">N/A</span>
          )}
        </dd>
      </div>
      <div className="py-5 grid grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Created at</dt>
        <dd className="col-span-2">
          <span className="text-sm text-gray-700 dark:text-gray-300">
            {format(new Date(props.user.createdAt), 'MMM d, yyyy')}
            <span className="text-sm text-gray-500 dark:text-gray-400">{` • ${format(new Date(props.user.createdAt), 'h:mm aa')}`}</span>
          </span>
        </dd>
      </div>
      <div className="py-5 grid grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Roles</dt>
        <dd className="col-span-2 space-y-5">
          {props.user.roles.length > 0 && (
            <ul role="list" className="border border-gray-200 dark:border-gray-700 rounded-md divide-y divide-gray-200 dark:divide-gray-700 pl-0">
              {props.user.roles.map((role) => (
                <li key={role.id} className="pl-4 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <span className="flex-1 w-0 truncate text-gray-700 dark:text-gray-300">{role.name}</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    {roleIDsBeingRemoved.has(role.id) ? (
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <a
                        href="#"
                        onClick={() => {
                          void handleRemoveRoleButtonClick(role)
                        }}
                        className="font-medium text-blue-500 hover:text-blue-700"
                      >
                        Remove
                      </a>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
          <div>
            <Select<Role, true>
              value={selectedRoles}
              options={roles}
              getOptionValue={(role) => role.id}
              getOptionLabel={(role) => role.name}
              isMulti
              isDisabled={isAssigningRolesToUser}
              styles={rolesSelectStyles}
              onChange={(roles) => setSelectedRoles([...roles])}
            />
            <div className="mt-3 flex justify-end items-center">
              <button
                type="button"
                disabled={isAssigningRolesToUser}
                onClick={() => {
                  void handleAssignRolesButtonClick()
                }}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 disabled:text-blue-500 disabled:bg-blue-50 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {isAssigningRolesToUser && (
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Assign
              </button>
            </div>
          </div>
        </dd>
      </div>
    </dl>
  )
};
