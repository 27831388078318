import React from 'react'

import type { TenantRegistrationStatus } from '../../../../types/registration'

export interface RegistrationStatusLabelProps {
  status: TenantRegistrationStatus
}

interface RegistrationStatusLabelStyle {
  label: string
  backgroundColor: string
  foregroundColor: string
}

const styleByStatus: Record<TenantRegistrationStatus, RegistrationStatusLabelStyle> = {
  draft: {
    label: 'Draft',
    backgroundColor: 'bg-violet-100 dark:bg-violet-900',
    foregroundColor: 'text-violet-800 dark:text-violet-100',
  },
  invited: {
    label: 'Invited',
    backgroundColor: 'bg-blue-100 dark:bg-blue-900',
    foregroundColor: 'text-blue-800 dark:text-blue-100',
  },
  active: {
    label: 'Active',
    backgroundColor: 'bg-emerald-100 dark:bg-emerald-900',
    foregroundColor: 'text-emerald-800 dark:text-emerald-100',
  },
}

export const RegistrationStatusLabel: React.FC<RegistrationStatusLabelProps> = (props) => {
  const style = styleByStatus[props.status]

  return (
    <>
      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${style.backgroundColor} ${style.foregroundColor}`}>{style.label}</span>
    </>
  )
}

export default RegistrationStatusLabel
