import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { makeEnumReadable } from "../util/string";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export interface ActionMenuProps {
  handleChange: (value?: string) => Promise<void>;
  options?: Array<{ label?: string; value: string }>;
}

export const ActionMenu: React.FC<ActionMenuProps> = (props) => {
  const { handleChange, options } = props;

  if (options === undefined || options?.length === 0) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-slate-300 dark:border-slate-700 shadow-sm px-2 py-1 bg-white dark:bg-slate-800 text-sm font-medium text-slate-700 dark:text-slate-300 hover:bg-slate-50 dark:hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-100 dark:focus:ring-offset-slate-800 focus:ring-indigo-500">
          Actions
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white dark:bg-slate-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
          <div className="py-1">
            {options.map((status, index) => (
              <Menu.Item key={`menu-${index}`}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => {
                      void handleChange(status?.value)
                    }}
                    className={classNames(
                      'w-full text-left',
                      active ? 'bg-slate-100 dark:bg-slate-700 text-slate-900 dark:text-slate-100' : 'text-slate-700 dark:text-slate-300',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {status?.label ?? makeEnumReadable(status?.value)}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
};

export default ActionMenu;
