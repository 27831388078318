import React from 'react'

export const GoogleDocIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 31 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>google-doc</title>
      <defs>
        <linearGradient x1="50.005%" y1="8.586%" x2="50.005%" y2="100.014%" id="linearGradient-1">
          <stop stopColor="#1A237E" strokeOpacity="0.2" offset="0%"></stop>
          <stop stopColor="#1A237E" strokeOpacity="0.02" offset="100%"></stop>
        </linearGradient>
        <radialGradient
          cx="3.168%"
          cy="2.76299396%"
          fx="3.168%"
          fy="2.76299396%"
          r="161.248%"
          gradientTransform="translate(0.0317, 0.0276), scale(1, 0.8611), scale(1, 1.1923), translate(-0.0317, -0.0276)"
          id="radialGradient-2"
        >
          <stop stopColor="#FFFFFF" offset="0%"></stop>
          <stop stopColor="#FFFFFF" strokeOpacity="0" offset="100%"></stop>
        </radialGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-5, -6)">
          <g id="google-doc" transform="translate(5, 6)">
            <path
              d="M2.98076923,0 L20.2692308,0 L31,9 L31,33.5 C31,34.8807119 29.6654642,36 28.0192308,36 L2.98076923,36 C1.33453585,36 0,34.8807119 0,33.5 L0,2.5 C0,1.11928813 1.33453585,0 2.98076923,0 Z"
              id="Path"
              fill="#4285F4"
              fillRule="nonzero"
            ></path>
            <path
              d="M0,33 C0,34.3807119 1.33453585,35.5 2.98076923,35.5 L28.0192308,35.5 C29.6654642,35.5 31,34.3807119 31,33 L31,33.5 C31,34.8807119 29.6654642,36 28.0192308,36 L2.98076923,36 C1.33453585,36 0,34.8807119 0,33.5 L0,33 Z"
              id="Path"
              fillOpacity="0.2"
              fill="#1A237E"
              fillRule="nonzero"
            ></path>
            <path
              d="M2.98076923,0 L20.2692308,0 L20.2692308,0.5 L2.98076923,0.5 C1.33453585,0.5 0,1.61928813 0,3 L0,2.5 C0,1.11928813 1.33453585,0 2.98076923,0 Z"
              id="Path"
              fillOpacity="0.2"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
            <polyline id="Path" fill="url(#linearGradient-1)" fillRule="nonzero" points="20.8653846 8 31 16.5 31 9"></polyline>
            <path
              d="M20.2692308,0 L31,9 L23.25,9 C21.6037666,9 20.2692308,7.88071187 20.2692308,6.5 L20.2692308,0 Z"
              id="Path"
              fill="#A1C2FA"
              fillRule="nonzero"
            ></path>
            <path
              d="M9,16 L23,16 L23,18 L9,18 L9,16 Z M9,20 L23,20 L23,22 L9,22 L9,20 Z M9,24 L23,24 L23,26 L9,26 L9,24 Z M9,28 L19,28 L19,30 L9,30 L9,28 Z"
              id="Shape"
              fill="#F1F1F1"
              fillRule="nonzero"
            ></path>
            <path
              d="M2.98076923,0 L20.2692308,0 L31,9 L31,33.5 C31,34.8807119 29.6654642,36 28.0192308,36 L2.98076923,36 C1.33453585,36 0,34.8807119 0,33.5 L0,2.5 C0,1.11928813 1.33453585,0 2.98076923,0 Z"
              id="Path"
              fillOpacity="0.1"
              fill="url(#radialGradient-2)"
              fillRule="nonzero"
            ></path>
            <path
              d="M7.6859642,10.875 C5.99529164,10.875 4.625,9.532 4.625,7.875 C4.625,6.218 5.99529164,4.875 7.6859642,4.875 C8.51140422,4.875 9.20369229,5.1725 9.73374926,5.657 L8.85627285,6.517 C8.53844273,6.2205 8.13541578,6.0685 7.68545404,6.0685 C6.68655939,6.0685 5.87693436,6.8965 5.87693436,7.8755 C5.87693436,8.8545 6.68706955,9.682 7.6859642,9.682 C8.59251977,9.682 9.21032438,9.1725 9.33531375,8.474 L7.6859642,8.474 L7.6859642,7.325 L10.5688823,7.325 C10.6045936,7.5225 10.625,7.7285 10.625,7.943 C10.625,9.657 9.45367103,10.875 7.6859642,10.875 L7.6859642,10.875 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GoogleDocIcon
