import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";

interface ReadinessCheckListItemProps {
  isComplete: boolean;
  isActive: boolean;
  isIncomplete: boolean;
  headingText: string;
  infoComponent?: JSX.Element;
  actionComponent?: JSX.Element;
}

const ReadinessCheckListItem: React.FC<ReadinessCheckListItemProps> = ({
  isComplete,
  isActive,
  isIncomplete,
  headingText,
  infoComponent,
  actionComponent,
}) => {
  return (
    <div className="flex flex-row w-full justify-between align-top">
      <div className="flex flex-row">
        {/* Checkmark in different states */}
        <div className="flex justify-center">
          <div
            className={classnames('w-5 h-5 mb-1 flex flex-col justify-center border border-transparent rounded-full text-center text-sm font-medium', {
              'bg-indigo-500 text-white': isComplete,
              'bg-indigo-900 dark:bg-indigo-300 text-white': isActive,
              'bg-white dark:bg-gray-800 text-indigo-500 dark:text-indigo-400 border-indigo-600 dark:border-indigo-400 outline-1 outline-dashed': isIncomplete,
            })}
          >
            {(isComplete || isActive) && <CheckIcon className="h-3 w-3 text-white flex self-center" />}
          </div>
        </div>
        <div className="flex flex-col ml-4">
          <strong>{headingText}</strong>
          <div className={classnames('text-sm', isIncomplete ? 'text-gray-500 dark:text-gray-400' : '')}>{infoComponent != null ? infoComponent : ''}</div>
        </div>
      </div>
      <div className="flex flex-col">{actionComponent != null ? actionComponent : null}</div>
    </div>
  )
};

export default ReadinessCheckListItem;
