import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getPaymentList,
  getTenantList,
  getPropertyList,
} from "./Payments.slice";
import Layout from "./Layout";
import Table from "../../../components/Table/Table";
import Badge from "../../../components/Badge";
import Panel from "../../../components/Panel";
import ComboboxAutocomplete from "../../../components/Form/ComboboxAutocomplete";
import qs from "qs";
import {
  formatUTCDate,
  formatCurrency,
  makeEnumReadable,
} from "../../../util/string";
import { Payment, PaymentSummaries } from "../../../apis/types";
import { useAppDispatch, useAppSelector } from "../../../shared/redux/hooks";

export interface PaymentsURLParams {
  propertyId: string | undefined;
  tenantId: string | undefined;
  status: string | undefined;
}

const Payments: React.FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const dispatch = useAppDispatch();
  const [propertyId, setPropertyId] = useState<string | undefined>(
    searchParams.get("propertyId") ?? undefined
  );
  const [tenantId, setTenantId] = useState<string | undefined>(
    searchParams.get("tenantId") ?? undefined
  );
  const [status, setStatus] = useState<string | null | undefined>(searchParams.get('status')) || null
  const navigate = useNavigate();
  // TODO To reenable the "Trailing 30-day totals" feature import summary here
  const { payments, tenants, properties } = useAppSelector(
    (state) => state.payments
  );

  // TODO Also remove this flag here and stop the Panel
  // below from being conditionally rendered based on it
  const isTrailing30DayTotalsDisabled = true;
  // TODO Also delete this hardcoded InvoiceSummary
  // TODO Also uncomment the call to getInvoiceSummary() on line 51
  const summary: PaymentSummaries | undefined = {
    CANCELLED: { number: 0, amount: 0 },
    CHARGEBACK: { number: 0, amount: 0 },
    FAILED: { number: 0, amount: 0 },
    PENDING: { number: 0, amount: 0 },
    PROCESSING: { number: 0, amount: 0 },
    SUCCEEDED: { number: 0, amount: 0 },
  };
  // TODO Also go to src/features/collections/invoices/Payments.slice.tsx and delete
  // the hardcoded api response for getPaymentSummary()

  useEffect(() => {
    // void dispatch(getPaymentSummary())
    void dispatch(getTenantList());
    void dispatch(getPropertyList());
  }, [dispatch]);

  useEffect(() => {
    void dispatch(
      getPaymentList({
        page: 0,
        propertyId,
        tenantId,
        status,
      })
    );
    navigate(
      `/collections/payments${qs.stringify(
        { propertyId, tenantId, status },
        { addQueryPrefix: true, skipNulls: true }
      )}`,
      { replace: true }
    );
  }, [propertyId, tenantId, status, dispatch, history]);

  const handlePaginatorClick = (page: number): void => {
    void dispatch(
      getPaymentList({
        page,
        propertyId,
        tenantId,
        status,
      })
    );
  };

  return (
    <>
      <Layout title="Payments">
        {!isTrailing30DayTotalsDisabled && (
          <Panel header="Trailing 30-day totals" className="mb-5">
            <div className="grid grid-flow-col text-center">
              {(Object.keys(summary ?? {}) as Array<keyof PaymentSummaries>).map((status, index) => (
                <div key={`status-${index}`}>
                  <div className="font-medium">
                    <Badge status={status} />
                  </div>
                  <div className="text-2xl">{summary?.[status]?.number}</div>
                  <div className="">{formatCurrency(summary?.[status]?.amount ?? 0)}</div>
                </div>
              ))}
            </div>
          </Panel>
        )}
        <Panel header="Filters" className="mb-5">
          <div className="grid grid-auto-flow grid-cols-3 gap-3">
            {/* <QuickSearch title="Tenant name" initialValue={quickSearchTerm} handleChange={handleQuickSearchChange} /> */}
            <div>
              <label htmlFor="propertyNames" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Property
              </label>
              {properties !== undefined && (
                <ComboboxAutocomplete labelPlural="Properties" value={propertyId ?? ''} handleChange={setPropertyId} data={properties} />
              )}
            </div>
            <div>
              <label htmlFor="tenantNames" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Tenant
              </label>
              {properties !== undefined && <ComboboxAutocomplete labelPlural="Tenants" value={tenantId ?? ''} handleChange={setTenantId} data={tenants} />}
            </div>
            <div>
              <label htmlFor="statusType" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Status
              </label>
              {properties !== undefined && (
                <ComboboxAutocomplete
                  labelPlural="Statuses"
                  value={status as string}
                  handleChange={(value: string | undefined) => setStatus(value ?? undefined)}
                  data={Object.keys(summary ?? {}).map((status: string) => ({
                    id: status,
                    name: makeEnumReadable(status),
                  }))}
                />
              )}
            </div>
          </div>
        </Panel>
        {!(payments == null) && (
          <Table<Payment>
            colConfig={[
              {
                label: 'Tenant / Property',
                render: (rec) => (
                  <>
                    <span className="font-medium">{rec.tenant.name}</span>
                    <br />
                    {rec.property.name}
                  </>
                ),
              },
              {
                label: 'Account number',
                render: (rec) => (
                  <Link
                    to={`/tenant/tenants/${rec.tenant.id}?serviceAccountNumber=${rec.serviceAccount.number}&tab=4`}
                    className="text-indigo-500 dark:text-indigo-400"
                  >
                    {rec.serviceAccount.number}
                  </Link>
                ),
              },
              {
                label: 'Invoice number',
                render: (rec) => rec.invoice.number,
              },
              {
                label: 'Payment number',
                render: (rec) => rec.paymentNumber,
              },
              {
                label: 'Amount',
                render: (rec) => formatCurrency(rec.amount),
              },
              {
                label: 'Payment type / method',
                render: (rec) => {
                  const { providerName, last4 } = rec.paymentMethod
                  const { type } = rec
                  const [paymentType, paymentTypeMethod] = type.split(' - ')
                  const paymentTypeMap: Record<string, string> = {
                    ONE_TIME_PAYMENT: 'One-time payment',
                    AUTOPAY: 'AutoPay',
                  }
                  const paymentTypeMethodMap: Record<string, string> = {
                    CHECKING: 'Checking ACH',
                    SAVINGS: 'Savings ACH',
                    CREDIT_CARD: 'Credit card',
                    CHECK: 'Check',
                  }
                  return (
                    <>
                      {paymentTypeMap[paymentType]} - {paymentTypeMethodMap[paymentTypeMethod]}
                      {providerName !== '' && (
                        <>
                          <br />
                          {providerName} {last4 !== null && `(••••${last4})`}
                        </>
                      )}
                    </>
                  )
                },
              },
              {
                label: 'Payment Date',
                render: (rec) => formatUTCDate(new Date(rec.paymentDate)),
              },
              {
                label: 'Posting Date',
                render: (rec) => formatUTCDate(new Date(rec.postingDate)),
              },
              {
                label: 'Memo',
                render: (rec) => rec.memo,
              },
              {
                label: 'Status',
                render: (rec) => <Badge status={rec.status} />,
              },
            ]}
            data={payments}
            handlePaginatorClick={handlePaginatorClick}
          />
        )}
      </Layout>
    </>
  )
};

export default Payments;
