import React from 'react'
import TagPopover from './TagPopover'
import ActionsBadge from './ActionsBadge'

interface BulkActionsProps {
  onAddTag: (fileIdsAndNamesToTag: Map<string, string>, tagKeyword: string) => Promise<void>
  onDeleteTag: (fileIdsAndNamesToUntag: Map<string, string>) => Promise<void>
  // downloadOnClick: () => void
  numRowsChecked: number
  tagsList: Array<{ id: string; name: string }>
  fileIdsAndNames: Map<string, string>
  onDownload: () => Promise<void>
}

const BulkActions: React.FC<BulkActionsProps> = ({ numRowsChecked, onAddTag, tagsList, fileIdsAndNames, onDeleteTag, onDownload }) => {
  return (
    <div className="flex px-3 py-3">
      <TagPopover onAddTag={onAddTag} tagsList={tagsList} fileIdsAndNames={fileIdsAndNames} onDeleteTag={onDeleteTag} />
      <p className="mr-2 text-sm py-0.5 text-slate-700 dark:text-slate-300">or</p>
      <ActionsBadge
        name="Download"
        onClick={() => {
          onDownload().catch((error) => {
            console.error('Error downloading file(s)', error)
          })
        }}
      />
      <p className="mr-2 text-sm py-0.5 text-slate-700 dark:text-slate-300">
        <span className="font-medium">{numRowsChecked}</span> selected files
      </p>
    </div>
  )
}

export default BulkActions
