import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";

interface ActionsPopoverProps {
  gDriveFileExternalLink: string;
  gDriveFolderExternalLink: string;
  getGDriveOpportunityFolderId: (opportunityId: string) => Promise<string>;
  opportunityId?: string | null;
  GOOGLE_DRIVE_FOLDER_LINK_HEADER: string;
  onDownload: () => Promise<void>;
  label: string;
}

const ActionsPopover: React.FC<ActionsPopoverProps> = ({
  gDriveFileExternalLink,
  gDriveFolderExternalLink,
  getGDriveOpportunityFolderId,
  opportunityId,
  GOOGLE_DRIVE_FOLDER_LINK_HEADER,
  onDownload,
  label,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [opportunityFolderLink, setOpportunityFolderLink] = useState("");

  useEffect(() => {
    const fetchGDriveOpportunityFolderId = async (): Promise<void> => {
      if (isPopoverOpen && opportunityId != null) {
        try {
          const gDriveOpportunityFolderId: string =
            await getGDriveOpportunityFolderId(opportunityId);
          const completeLink = `${GOOGLE_DRIVE_FOLDER_LINK_HEADER}${gDriveOpportunityFolderId}`;
          setOpportunityFolderLink(completeLink);
        } catch (error) {
          console.error(error);
        }
      }
    };

    void fetchGDriveOpportunityFolderId();
  }, [isPopoverOpen]);

  return (
    <Popover className="relative" as="div">
      {({ open }) => {
        return (
          <>
            <Popover.Button
              className="inline-flex items-center px-2.5 py-0.5 mr-2 rounded-md font-medium text-indigo-900 dark:text-indigo-100 bg-indigo-100 dark:bg-indigo-900 disabled:text-indigo-500 disabled:bg-indigo-50 dark:disabled:text-indigo-400 dark:disabled:bg-indigo-950 disabled:cursor-not-allowed border border-transparent hover:bg-indigo-200 dark:hover:bg-indigo-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
              onClick={() => setIsPopoverOpen((prev) => !prev)}
            >
              {label}
              <ChevronDownIcon className={classnames('ml-1 w-5 h-5', open ? 'rotate-180 transform' : '')} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 mt-2 w-56 max-h-60 overflow-y-auto right-0 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {({ close }) => {
                  const handleClose = (): void => {
                    setIsPopoverOpen(false)
                    close()
                  }
                  return (
                    <>
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button
                          key={'download'}
                          onClick={() => {
                            onDownload()
                              .then(handleClose)
                              .catch((error) => {
                                console.error('Error downloading file', error)
                              })
                          }}
                          className="w-full text-left block px-4 py-2 text-sm text-gray-900 dark:text-gray-100 hover:bg-indigo-600 hover:text-white"
                        >
                          Download
                        </button>
                      </div>
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" onClick={handleClose}>
                        <a
                          href={gDriveFileExternalLink}
                          target="_blank"
                          rel="noreferrer"
                          className="w-full text-left block px-4 py-2 text-sm text-gray-900 dark:text-gray-100 hover:bg-indigo-600 hover:text-white"
                          role="menuitem"
                        >
                          Open file
                        </a>
                      </div>
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" onClick={handleClose}>
                        <a
                          href={gDriveFolderExternalLink}
                          target="_blank"
                          rel="noreferrer"
                          className="w-full text-left block px-4 py-2 text-sm text-gray-900 dark:text-gray-100 hover:bg-indigo-600 hover:text-white"
                          role="menuitem"
                        >
                          Open parent folder
                        </a>
                      </div>
                      {/* If this file is tied to an opportunity show the enabled link, otherwise disable it */}
                      {opportunityFolderLink.startsWith(GOOGLE_DRIVE_FOLDER_LINK_HEADER) ? (
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" onClick={handleClose}>
                          <a
                            href={opportunityFolderLink}
                            target="_blank"
                            rel="noreferrer"
                            className="w-full text-left block px-4 py-2 text-sm text-gray-900 dark:text-gray-100 hover:bg-indigo-600 hover:text-white"
                            role="menuitem"
                          >
                            Open opportunity folder
                          </a>
                        </div>
                      ) : (
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          <a
                            className="w-full text-left block px-4 py-2 text-sm text-gray-900 dark:text-gray-100 opacity-50 cursor-default"
                            role="menuitem"
                            aria-disabled="true"
                          >
                            Open opportunity folder
                          </a>
                        </div>
                      )}
                    </>
                  )
                }}
              </Popover.Panel>
            </Transition>
          </>
        )
      }}
    </Popover>
  );
};

export default ActionsPopover;
