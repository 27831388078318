import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'
import Nav from './components/Nav/Nav'
import Toaster from './components/Toaster/Toaster'

interface PropInterface {
  children: React.ReactNode;
}

const CoreLayout: React.FC<PropInterface> = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="flex flex-row w-full">
      <Nav />
      <div className="flex-[0.9] overflow-auto h-screen">{props.children}</div>
      <Toaster />
    </div>
  )
};

export default CoreLayout;
