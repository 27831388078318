import React from 'react'

interface IconTenantProps {
  className?: string
}

const IconTenant: React.FC<IconTenantProps> = ({ className }) => {
  return (
    <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
      <title>icon-tenant</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-76, -304)">
          <g id="icon-tenant" transform="translate(76, 304)">
            <path
              d="M20.8245641,28 L20.8245641,20.0833333 C20.8245641,19.6461079 21.1775723,19.2916667 21.6130298,19.2916667 L24.7668925,19.2916667 C25.2023501,19.2916667 25.5553582,19.6461079 25.5553582,20.0833333 L25.5553582,28 M20.8245641,28 L9.11322066,28 M20.8245641,28 L25.5553582,28 M25.5553582,28 L29.3820449,28 M27.9207552,28 L27.9207552,15.7017222 M10.5745104,27.9989444 L10.5745104,15.7027778 M10.5745104,15.7027778 C11.8750702,16.4557458 13.5239387,16.1842336 14.5168387,15.0536111 C15.1150973,15.7357582 15.9769837,16.1261432 16.8822358,16.125 C17.8241894,16.125 18.6694246,15.7101667 19.2476328,15.0525556 C19.8456986,15.7350945 20.7076118,16.1258763 21.6130298,16.125 C22.5549835,16.125 23.4002187,15.7101667 23.9784268,15.0525556 C24.9716038,16.1828222 26.6204356,16.453887 27.9207552,15.7006667 M10.5745104,15.7006667 C9.72657427,15.2072314 9.15485286,14.3464885 9.02701083,13.3708619 C8.8991688,12.3952353 9.2296959,11.4153036 9.9216608,10.7184444 L11.1716417,9.46444444 C11.46709,9.16742697 11.8679192,9.00036955 12.2860065,9 L26.2082078,9 C26.6262951,9.00036955 27.0271243,9.16742697 27.3225726,9.46444444 L28.5736048,10.7195 C29.2672083,11.4155912 29.5988504,12.39603 29.4709279,13.3722706 C29.3430054,14.3485111 28.7700763,15.2094543 27.9207552,15.7017222 M13.7283731,24.8322778 L17.6707014,24.8322778 C18.106159,24.8322778 18.4591671,24.4778365 18.4591671,24.0406111 L18.4591671,20.0833333 C18.4591671,19.6461079 18.106159,19.2916667 17.6707014,19.2916667 L13.7283731,19.2916667 C13.2929155,19.2916667 12.9399074,19.6461079 12.9399074,20.0833333 L12.9399074,24.0416667 C12.9399074,24.4797222 13.29314,24.8333333 13.7283731,24.8333333 L13.7283731,24.8322778 Z"
              id="Shape"
              className="stroke-blue-600 dark:stroke-blue-400"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle id="Oval-Copy-2" fillOpacity="0.056517701" className="fill-blue-400 dark:fill-blue-200" fillRule="nonzero" cx="19" cy="19" r="19" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconTenant
