import React from 'react'

interface IconPropertyProps {
  className?: string
}

const IconProperty: React.FC<IconPropertyProps> = ({ className }) => {
  return (
    <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
      <title>icon-property</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard-Copy" transform="translate(-22.000000, -103.000000)">
          <g id="icon-property" transform="translate(22.000000, 103.000000)">
            <circle id="Oval-Copy" fillOpacity="0.056517701" className="fill-purple-400 dark:fill-purple-200" cx="19" cy="19" r="19" />
            <g id="noun-building-1010016" transform="translate(8.000000, 8.000000)" className="fill-purple-600 dark:fill-purple-400" fillRule="nonzero">
              <path
                d="M21.999617,22 L12.3748351,22 L12.3748351,15.5832996 L9.62478188,15.5832996 L9.62478188,22 L0,22 L0,1.73527972 C0,0.778371247 0.778366729,0 1.73526965,0 L20.2647303,0 C21.2216333,0 22,0.778371247 22,1.73527972 L21.999617,22 Z M13.291562,21.0833103 L21.0833156,21.0833103 L21.0833156,1.73532228 C21.0833156,1.28363799 20.7162415,0.916732279 20.2645641,0.916732279 L1.73510342,0.916732279 C1.28342174,0.916732279 0.916518163,1.28364224 0.916518163,1.73532228 L0.916518163,21.0833103 L8.70827172,21.0833103 L8.70810549,14.6666099 L13.2913147,14.6666099 L13.291562,21.0833103 Z"
                id="Shape"
              />
              <path
                d="M7,6.79178717 L3,6.79178717 L3,2.79178717 L6.99983493,2.79178717 L7,6.79178717 Z M3.8888843,5.90290287 L6.1111157,5.90290287 L6.1111157,3.68067147 L3.8888843,3.68067147 L3.8888843,5.90290287 Z"
                id="Shape"
              />
              <path
                d="M7,18.7917872 L3,18.7917872 L3,14.7917872 L6.99983493,14.7917872 L7,18.7917872 Z M3.8888843,17.9028662 L6.1111157,17.9028662 L6.1111157,15.6805431 L3.8888843,15.6805431 L3.8888843,17.9028662 Z"
                id="Shape"
              />
              <path
                d="M7,12.7917872 L3,12.7917872 L3,8.79178717 L6.99983493,8.79178717 L7,12.7917872 Z M3.8888843,11.9028662 L6.1111157,11.9028662 L6.1111157,9.68054308 L3.8888843,9.68054308 L3.8888843,11.9028662 Z"
                id="Shape"
              />
              <path
                d="M13,6.79178717 L9,6.79178717 L9,2.79178717 L13,2.79178717 L13,6.79178717 Z M9.88892099,5.90290287 L12.1112441,5.90290287 L12.1112441,3.68067147 L9.88892099,3.68067147 L9.88892099,5.90290287 Z"
                id="Shape"
              />
              <path
                d="M13,12.7917872 L9,12.7917872 L9,8.79178717 L13,8.79178717 L13,12.7917872 Z M9.88892099,11.9028662 L12.1112441,11.9028662 L12.1112441,9.68054308 L9.88892099,9.68054308 L9.88892099,11.9028662 Z"
                id="Shape"
              />
              <path
                d="M19,6.79178717 L15.0001612,6.79178717 L15,2.79178717 L18.9998388,2.79178717 L19,6.79178717 Z M15.8888813,5.90290287 L18.1111148,5.90290287 L18.1111148,3.68067147 L15.8888813,3.68067147 L15.8888813,5.90290287 Z"
                id="Shape"
              />
              <path
                d="M19,19 L15,19 L15,15 L19,15 L19,19 Z M15.8887559,18.111079 L18.111079,18.111079 L18.111079,15.8887559 L15.8887559,15.8887559 L15.8887559,18.111079 Z"
                id="Shape"
              />
              <path
                d="M19,13 L15,13 L15,9 L19,9 L19,13 Z M15.8887559,12.111079 L18.111079,12.111079 L18.111079,9.88875591 L15.8887559,9.88875591 L15.8887559,12.111079 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconProperty
