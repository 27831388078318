import React from "react";
import {
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import classnames from "classnames";

interface PaginatorProps {
  data: {
    total?: number;
    resultsPerPage?: number;
    page?: number;
  };
  handlePaginatorClick?: (page: number) => void;
}

export const Paginator: React.FC<PaginatorProps> = (props) => {
  const {
    data: { total = 0, resultsPerPage = 0, page = 0 } = {},
    handlePaginatorClick,
  } = props;

  if (total === undefined) {
    return null;
  }

  const totalPages = Math.ceil(total / resultsPerPage);
  const pageArr: number[] = [];

  for (let i = 0; i < totalPages; i++) {
    pageArr.push(i);
  }

  const getTruncatedPageArr: any = () => {
    if (page < 3 || totalPages < 5) {
      return pageArr.slice(0, 5);
    } else if (page > totalPages - 3) {
      return pageArr.slice(totalPages - 5, totalPages);
    }
    return pageArr.slice(page - 2, page + 3);
  };

  const handlePageClick = (
    requestedPage: "first" | "prev" | "next" | "last" | number
  ): void => {
    switch (requestedPage) {
      case "first":
        if (page === 0) {
          break;
        }
        handlePaginatorClick?.(0);
        break;
      case "prev":
        if (page === 0) {
          break;
        }
        handlePaginatorClick?.(page - 1);
        break;
      case "next":
        if (page + 1 === totalPages) {
          break;
        }
        handlePaginatorClick?.(page + 1);
        break;
      case "last":
        if (page + 1 === totalPages) {
          break;
        }
        handlePaginatorClick?.(totalPages - 1);
        break;
      default:
        handlePaginatorClick?.(requestedPage - 1);
        break;
    }
  };

  const pageButton = (page: number, current = false): React.ReactNode => (
    <button
      type="button"
      key={page}
      onClick={() => handlePageClick(page)}
      disabled={current}
      className={classnames(
        'border-slate-300 text-slate-700 md:inline-flex relative items-center px-4 py-2 border text-sm font-medium dark:border-slate-700 dark:text-slate-300',
        {
          'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 dark:bg-indigo-900 dark:border-indigo-500 dark:text-indigo-300 md:inline-flex relative items-center px-4 py-2 border text-sm font-medium':
            current,
          'hover:bg-slate-50 dark:hover:bg-slate-700': !current,
        }
      )}
    >
      {page}
    </button>
  )

  const pageButtons = (): React.ReactNode => {
    const truncatedPageArray = getTruncatedPageArr()
    return <>{truncatedPageArray?.map((p: number) => pageButton(p + 1, page === p))}</>
  }

  return (
    <div className="px-3 py-3 flex items-center justify-between border-t text-sm border-slate-200 bg-white dark:bg-slate-950 dark:border-slate-700">
      {totalPages > 1 ? (
        <div className="flex-1 flex justify-between">
          <div>
            <p className="text-sm text-slate-700 dark:text-slate-300">
              Showing <span className="font-semibold">{resultsPerPage * (page + 1) - resultsPerPage + 1}</span> to{' '}
              <span className="font-semibold">{resultsPerPage * (page + 1) > total ? total : resultsPerPage * (page + 1)}</span> of{' '}
              <span className="font-semibold">{total}</span> results
            </p>
          </div>
        </div>
      ) : (
        <div className="flex-1 flex justify-between">
          <p className="text-sm text-slate-700 dark:text-slate-300">
            Showing <span className="font-semibold">{total}</span> result
            {total > 1 && 's'}
          </p>
        </div>
      )}
      <div className="text-slate-700 dark:text-slate-300">
        Page <span className="font-semibold">{page + 1}</span> of <span className="font-semibold">{totalPages}</span>
      </div>
      {totalPages > 1 && (
        <nav className="ml-6 relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <button
            type="button"
            onClick={() => handlePageClick('first')}
            disabled={page === 0}
            className={classnames(
              'relative inline-flex items-center px-2 py-2 rounded-l-md border border-slate-300 bg-white text-sm font-medium text-slate-700 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300',
              {
                'hover:bg-slate-50 dark:hover:bg-slate-700': page !== 0,
                'opacity-50': page === 0,
              }
            )}
          >
            <ChevronDoubleLeftIcon className="h-5 w-5" /> First
          </button>
          <button
            type="button"
            onClick={() => handlePageClick('prev')}
            disabled={page === 0}
            className={classnames(
              'relative inline-flex items-center px-2 py-2 border border-slate-300 bg-white text-sm font-medium text-slate-700 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300',
              {
                'hover:bg-slate-50 dark:hover:bg-slate-700': page !== 0,
                'opacity-50': page === 0,
              }
            )}
          >
            <ChevronLeftIcon className="h-5 w-5" /> Prev
          </button>

          {pageButtons()}

          <button
            type="button"
            onClick={() => handlePageClick('next')}
            disabled={page === totalPages - 1}
            className={classnames(
              'relative inline-flex items-center px-2 py-2 border border-slate-300 bg-white text-sm font-medium text-slate-700 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300',
              {
                'hover:bg-slate-50 dark:hover:bg-slate-700': page !== totalPages - 1,
                'opacity-50': page === totalPages - 1,
              }
            )}
          >
            Next <ChevronRightIcon className="h-5 w-5" />
          </button>
          <button
            type="button"
            onClick={() => handlePageClick('last')}
            disabled={page === totalPages - 1}
            className={classnames(
              'relative inline-flex items-center px-2 py-2 rounded-r-md border border-slate-300 bg-white text-sm font-medium text-slate-700 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300',
              {
                'hover:bg-slate-50 dark:hover:bg-slate-700': page !== totalPages - 1,
                'opacity-50': page === totalPages - 1,
              }
            )}
          >
            Last <ChevronDoubleRightIcon className="h-5 w-5" />
          </button>
        </nav>
      )}
    </div>
  )
};

export default Paginator;
