import React, { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";
import ComboboxAutocomplete from "../../../components/Form/ComboboxAutocomplete";

interface TagPopoverProps {
  tagsList: Array<{ id: string; name: string; description?: string }>;
  onAddTag: (
    fileIdsAndNamesToTag: Map<string, string>,
    tagKeyword: string
  ) => Promise<void>;
  onDeleteTag: (fileIdsAndNamesToUntag: Map<string, string>) => Promise<void>;
  fileIdsAndNames: Map<string, string>;
  dropDownRight?: boolean;
}

const TagPopover: React.FC<TagPopoverProps> = ({
  tagsList,
  onAddTag,
  fileIdsAndNames,
  onDeleteTag,
  dropDownRight,
}) => {
  // Allows the ComboboxAutocomplete autofocus to be focused on mount if autoFocus is true
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (isPopoverOpen) {
      setShouldAutoFocus(true);
    }
  }, [isPopoverOpen]);

  return (
    <Popover className="relative">
      {({ open }) => {
        if (open) setIsPopoverOpen(true);
        return (
          <>
            <Popover.Button className="inline-flex items-center px-2.5 py-0.5 mr-2 rounded-md font-medium text-indigo-900 dark:text-indigo-100 bg-indigo-100 dark:bg-indigo-900 disabled:text-indigo-500 dark:disabled:text-indigo-400 disabled:bg-indigo-50 dark:disabled:bg-indigo-900 disabled:cursor-not-allowed border border-transparent hover:bg-indigo-200 dark:hover:bg-indigo-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500">
              Tag
              <ChevronDownIcon className={classnames('ml-1 w-5 h-5', open ? 'rotate-180 transform' : '')} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={classnames(
                  'absolute z-20 mt-2 w-screen max-w-sm max-h-60  rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none',
                  dropDownRight === true ? 'right-0 origin-top-right' : 'left-0 origin-top-left'
                )}
              >
                {({ close }) => (
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button
                      key={'Delete'}
                      onClick={() => {
                        /* handle deleting a tag */
                        onDeleteTag(fileIdsAndNames).catch((error) => {
                          throw new Error(error)
                        })
                        setIsPopoverOpen(false)
                        close()
                      }}
                      className="w-full text-left block px-4 py-2 text-sm text-gray-900 dark:text-gray-100 hover:bg-indigo-600 hover:text-white border-b border-gray-200 dark:border-gray-700"
                      role="menuitem"
                    >
                      {'Remove Tag(s)'}
                    </button>
                    <div className="py-1 px-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <ComboboxAutocomplete
                        // labelPlural="Please add a tag"
                        value={'Search for a tag to add'}
                        handleChange={(tag) => {
                          onAddTag(fileIdsAndNames, tag as string).catch((error) => {
                            if (tag === 'Search for a tag to add') {
                              throw new Error('Please select a valid tag')
                            } else {
                              throw new Error(error)
                            }
                          })
                          setIsPopoverOpen(false)
                          close()
                        }}
                        data={tagsList}
                        autoFocus={shouldAutoFocus}
                        wildCardDisabled
                        placeholder="Search for a tag to add"
                        isNotFilter
                      />
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )
      }}
    </Popover>
  );
};

export default TagPopover;
