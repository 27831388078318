import React, { useMemo, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../../components/Form/Button'
import Input from '../../../../components/Form/Input'
import OnboardingSteps from './OnboardingSteps'
import OnboardingStep from './OnboardingStep'
import CopyToClipboard from './CopyToClipboard'
import { generateAccountInvitationApi, updateAgreementFromUploadApi, createAgreementFromUploadApi } from '../../../../apis/apis'
import { updateTESASupplementAgreementStatusApi, markRegistrationCompleteApi } from '../../../../apis/registration'
import { pushToast } from '../../../../components/Toaster/Toaster.slice'
import { useFormik, Field, FormikProvider } from 'formik'
import { object, array, string } from 'yup'
import { formatPhoneNumber, formatTitleCase, formatUTCDate } from '../../../../util/string'
import type { TenantRegistration as Onboarding, UtilityAccount, AgreementSubmission, ExternallySignedAgreementDocument } from '../../../../types/registration'
import classnames from 'classnames'
import type { FormikErrors } from 'formik'
import { format } from 'date-fns'
import { useAppDispatch } from '../../../../shared/redux/hooks'
import { CustomContentModal } from '../../../../components/CustomContentModal'
import Datepicker from 'react-tailwindcss-datepicker'
import { Select } from './Select'

export interface OnboardingEditProps {
  onboarding: Onboarding
  refreshList: any
  updateOnboardingFn: (onboarding: Onboarding) => Promise<void>
  closeFn: () => void
}

export const OnboardingRecordEdit: React.FC<OnboardingEditProps> = (props: OnboardingEditProps) => {
  const [isFetchingApi, setFetchingApi] = useState(false)

  // State for handling uploading new documents and changing existing documents
  const [showUploadDateModal, setShowUploadDateModal] = useState<boolean>(false)
  const [uploadedDate, setUploadedDate] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: new Date(),
    endDate: new Date(),
  })

  const [newUpload, setNewUpload] = useState<boolean | null>(null)
  const [selectedAgreementDocRefIndex, setSelectedAgreementDocRefIndex] = useState<number | null>(null)

  const [agreementDocType, setAgreementDocType] = useState<{ label: string; value: string }>({ label: 'Utility Authorization', value: 'TESA_SUPPLEMENT' })
  const [showAgreementDocTypeOptions, setShowAgreementDocTypeOptions] = useState<boolean>(false)

  const tesaSupplements = props.onboarding.invites?.ONBOARDING_REGISTRATION?.agreementSubmissions ?? []

  const externallySingedAgreementDocuments = props.onboarding.invites?.ONBOARDING_REGISTRATION?.externallySignedAgreementDocuments ?? []
  const externallySignedTesas = externallySingedAgreementDocuments.filter((doc) => doc.type === 'TESA')

  const dispatch = useAppDispatch()
  const requireStr = 'This field is required'
  const formik = useFormik({
    initialValues: {
      registration: props.onboarding,
      selectedAccountIdx: 0,
      selectedAgreementIdx: 0,
    },
    enableReinitialize: true,
    validationSchema: object().shape({
      registration: object().shape({
        tenant: object().shape({
          name: string().required(requireStr),
          contact: object().shape({
            firstName: string().required(requireStr),
            lastName: string().required(requireStr),
            emailAddress: string().email('Invalid email').required(requireStr),
            phoneNumber: string()
              .test('phone', '10 digits required', (value): boolean => {
                return value?.replace(/\D/g, '').match(/^\d{10}$/) !== null
              })
              .required(requireStr),
            title: string(),
          }),
          accounts: array().of(
            object().shape({
              name: string().required(requireStr),
              number: string().required(requireStr),
              tenantType: string(),
            })
          ),
        }),
      }),
    }),
    onSubmit: async (values) => {
      await props.updateOnboardingFn(values.registration)
    },
  })
  const { values, errors, touched, isSubmitting, setFieldValue, handleBlur, handleSubmit, validateField } = formik

  const accountOptions = useMemo(() => {
    const options = values.registration.tenant.accounts.map((account, idx) => ({
      value: `${idx}`,
      label: account.name,
    }))

    return options
  }, [values.registration.tenant.accounts])

  const agreementOptions = useMemo(() => {
    const options = (values.registration.tenant.accounts[values.selectedAccountIdx].agreements ?? []).map((agreement, idx) => ({
      value: `${idx}`,
      label: agreement.number,
    }))

    return options
  }, [values.registration.tenant.accounts, values.selectedAccountIdx])

  const {
    ONBOARDING_REGISTRATION,
    ACCOUNT_SIGNUP,
    isOnregInviteCreated,
    isOnregInvitePending,
    isOnregAgreedTesa,
    isOnregAgreedTesaSupplement,
    isOnregAgreedPricingAndPayments,
    isOnregInviteConsumed,
    isAccountInviteCreated,
    isAccountInviteConsumed,
  } = values.registration.invites

  const agreementSubmissions = props.onboarding.invites?.ONBOARDING_REGISTRATION?.agreementSubmissions ?? []
  const agreementInvitationCuid = props.onboarding.invites?.ONBOARDING_REGISTRATION?.id ?? ''

  const canEditForm = !isAccountInviteConsumed || agreementSubmissions[0]?.status === 'REJECTED'

  // Ref for new file input
  const uploadNewDocInputRef = useRef<HTMLInputElement>(null)

  // Array of refs for "Change Document..." input
  const changeDocInputRefs = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    // Adjust the refs array to match the number of documents
    changeDocInputRefs.current = externallySingedAgreementDocuments.map((_, i) => changeDocInputRefs.current[i] || React.createRef())
  }, [externallySingedAgreementDocuments.length])

  const handleUploadClick = (newSubmission: boolean) => {
    setShowUploadDateModal(true)

    setNewUpload(newSubmission)
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    newSubmission: boolean,
    agreementType: string,
    agreementCuid?: string
  ): Promise<void> => {
    const file = event.target.files?.[0]

    if (file == null) {
      return
    }

    if (newSubmission === false && agreementCuid == null) {
      throw new Error('agreementCuid must be provided when newSubmission is false')
    }

    // Create a FormData object and append the file, signed date, and agreement type
    const formData = new FormData()
    formData.append('file', file)

    const { startDate } = uploadedDate

    const submissionDateTimeInMilliseconds =
      startDate != null ? new Date(startDate.setUTCHours(0, 0, 0, 0)).getTime() : new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime()

    formData.append('dateSigned', submissionDateTimeInMilliseconds + '')
    formData.append('type', agreementType)

    try {
      if (newSubmission === true) {
        await createAgreementFromUploadApi(agreementInvitationCuid, formData)
      } else {
        await updateAgreementFromUploadApi(agreementCuid as string, formData)
      }
      await dispatch(pushToast({ type: 'success', message: 'File uploaded' }))
      setUploadedDate({ startDate: null, endDate: null })
      await props.refreshList()

      // Reset the input value so the same file can be uploaded again
      event.target.value = ''

      // Set selected doc type back to TESA_SUPPLEMENT
      setAgreementDocType({ label: 'Utility Authorization', value: 'TESA_SUPPLEMENT' })
    } catch (e) {
      console.error(e)
      await dispatch(
        pushToast({
          type: 'error',
          message: 'Error deleting tag',
          description: `${
            (e as XMLHttpRequest).response?.data?.message !== undefined ? `${(e as XMLHttpRequest).response?.data?.message as string}:` : 'Error:'
          } ${(e as { message: string }).message}`,
        })
      )
    }
  }

  return (
    <div className="max-w-screen-lg h-full shadow-xl rounded-2xl justify-center my-8 p-6 py-0 text-left bg-white dark:bg-slate-800">
      {props.onboarding.isNational === true ? (
        <div className={`-mx-6 py-2 bg-emerald-600 dark:bg-emerald-700 text-white text-center text-sm font-semibold rounded-t-xl`}>National Account</div>
      ) : null}
      {props.onboarding.isComplete === false && (
        <div
          className={`-mx-6 py-2 text-white text-sm font-semibold text-center bg-indigo-500 dark:bg-indigo-600 ${
            props.onboarding.isNational === true ? '' : 'rounded-t-xl'
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="inline mr-1 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>This record may be incomplete</span>
        </div>
      )}
      <OnboardingSteps className="p-6 -mx-6 bg-slate-50 dark:bg-slate-900 grid grid-cols-7 rounded-t-2xl">
        <OnboardingStep
          title="Registration invitation generated"
          timestamp={ONBOARDING_REGISTRATION.createDate}
          showTimestamp
          isActive={isOnregInviteCreated}
        ></OnboardingStep>

        <OnboardingStep
          title="TESA accepted"
          timestamp={ONBOARDING_REGISTRATION.lastUpdatedDate}
          showTimestamp={isOnregInvitePending && isOnregAgreedTesa}
          isActive={isOnregInvitePending && isOnregAgreedTesa}
        ></OnboardingStep>

        <OnboardingStep
          title="Utility Authorization signed"
          timestamp={ONBOARDING_REGISTRATION.lastUpdatedDate}
          showTimestamp={isOnregInvitePending && isOnregAgreedTesaSupplement}
          isActive={isOnregInvitePending && isOnregAgreedTesaSupplement}
        ></OnboardingStep>

        <OnboardingStep
          title="Payments &amp; pricing terms accepted"
          timestamp={ONBOARDING_REGISTRATION.lastUpdatedDate}
          showTimestamp={isOnregInvitePending && isOnregAgreedPricingAndPayments}
          isActive={isOnregInvitePending && isOnregAgreedPricingAndPayments}
        ></OnboardingStep>

        <OnboardingStep
          title="Registration complete"
          timestamp={ONBOARDING_REGISTRATION.lastUpdatedDate}
          showTimestamp={isOnregInviteConsumed}
          isActive={isOnregInviteConsumed}
        ></OnboardingStep>

        <OnboardingStep
          title="Account invitation generated"
          timestamp={ACCOUNT_SIGNUP?.createDate}
          showTimestamp={isAccountInviteCreated}
          isActive={isAccountInviteCreated}
        ></OnboardingStep>

        <OnboardingStep
          title="Account created"
          timestamp={ACCOUNT_SIGNUP?.lastUpdatedDate}
          showTimestamp={isAccountInviteConsumed}
          isActive={isAccountInviteConsumed}
        ></OnboardingStep>
      </OnboardingSteps>

      <FormikProvider value={formik}>
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-y-4 gap-x-4">
            <div className="col-span-6 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Business name"
                name="registration.tenant.name"
                required
                displayField={!canEditForm}
                value={values.registration.tenant.name}
                disabled={isSubmitting}
                error={
                  errors.registration?.tenant?.name !== undefined && (touched.registration?.tenant?.name ?? false)
                    ? errors.registration?.tenant?.name
                    : undefined
                }
              />
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Tax ID / TIN / EIN"
                name="registration.tenant.ein"
                displayField={true}
                value={values.registration.tenant.ein}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-12 border-b-2 border-slate-200 dark:border-slate-600">
              <h4 className="pb-2 col-span-12 text-md items-center font-medium leading-4 text-slate-900 dark:text-slate-100">Contact</h4>
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="First name"
                name="registration.tenant.contact.firstName"
                required
                displayField={!canEditForm}
                value={values.registration.tenant.contact.firstName}
                disabled={isSubmitting}
                error={
                  errors.registration?.tenant?.contact?.firstName !== undefined && (touched.registration?.tenant?.contact?.firstName ?? false)
                    ? errors.registration?.tenant?.contact?.firstName
                    : undefined
                }
              />
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Last name"
                name="registration.tenant.contact.lastName"
                required
                displayField={!canEditForm}
                value={values.registration.tenant.contact.lastName}
                disabled={isSubmitting}
                error={
                  errors.registration?.tenant?.contact?.lastName !== undefined && (touched.registration?.tenant?.contact?.lastName ?? false)
                    ? errors.registration?.tenant?.contact?.lastName
                    : undefined
                }
              />
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Title"
                name="registration.tenant.contact.title"
                displayField={!canEditForm}
                onBlur={async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
                  const name = 'registration.tenant.contact.title'
                  await setFieldValue(name, values.registration.tenant.contact.title, true)
                  setImmediate(() => {
                    void validateField(name)
                    handleBlur(event)
                  })
                }}
                value={values.registration.tenant.contact.title}
                disabled={isSubmitting}
                error={
                  errors.registration?.tenant?.contact?.title !== undefined && (touched.registration?.tenant?.contact?.title ?? false)
                    ? errors.registration?.tenant?.contact?.title
                    : undefined
                }
              />
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100"></div>
            <div className="col-span-6 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Email address"
                name="registration.tenant.contact.emailAddress"
                required
                displayField={!canEditForm}
                value={values.registration.tenant.contact.emailAddress}
                disabled={isSubmitting}
                error={
                  errors.registration?.tenant?.contact?.emailAddress !== undefined && (touched.registration?.tenant?.contact?.emailAddress ?? false)
                    ? errors.registration?.tenant?.contact?.emailAddress
                    : undefined
                }
              />
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Phone number"
                name="registration.tenant.contact.phoneNumber"
                required
                displayField={!canEditForm}
                onBlur={async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
                  const name = 'registration.tenant.contact.phoneNumber'
                  await setFieldValue(name, formatPhoneNumber(values.registration.tenant.contact.phoneNumber), true)
                  setImmediate(() => {
                    void validateField(name)
                    handleBlur(event)
                  })
                }}
                value={values.registration.tenant.contact.phoneNumber}
                disabled={isSubmitting}
                error={
                  errors.registration?.tenant?.contact?.phoneNumber !== undefined && (touched.registration?.tenant?.contact?.phoneNumber ?? false)
                    ? errors.registration?.tenant?.contact?.phoneNumber
                    : undefined
                }
              />
            </div>
            <div className="col-span-12 border-b-2 overflow-x-auto overflow-y-hidden border-slate-200 dark:border-slate-600">
              <span className="pb-2 mr-4 inline-flex flex-col justify-center text-md font-medium leading-4 text-slate-900 dark:text-slate-100">
                {props.onboarding.utility} Utility Accounts:
              </span>
              {accountOptions.map((account, index) => (
                <a
                  key={index}
                  className={classnames(
                    account.value === accountOptions[values.selectedAccountIdx].value
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap mb-1 mx-2 p-1 border-b-2 font-medium text-sm'
                  )}
                  onClick={() => {
                    void setFieldValue('selectedAccountIdx', account.value)
                    void setFieldValue('selectedAgreementIdx', agreementOptions[0].value)
                  }}
                >
                  UA {index + 1}
                </a>
              ))}
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Utility account number"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].number`}
                required
                type="text"
                displayField={!canEditForm}
                className={`mt-1 text-sm rounded-md border-transparent ${
                  (errors.registration?.tenant?.accounts as Array<FormikErrors<UtilityAccount>> | undefined)?.[values.selectedAccountIdx]?.number !==
                    undefined &&
                  (touched.registration?.tenant?.accounts?.[values.selectedAccountIdx]?.number ?? false)
                    ? 'bg-red-50 dark:bg-red-900 border-red-500 focus:border-red-500'
                    : 'focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white dark:focus:bg-slate-700'
                } focus:ring-0`}
                value={values.registration.tenant.accounts[values.selectedAccountIdx].number}
                disabled={isSubmitting}
              />
              {(errors.registration?.tenant?.accounts as Array<FormikErrors<UtilityAccount>> | undefined)?.[values.selectedAccountIdx]?.number !== undefined &&
              (touched.registration?.tenant?.accounts?.[values.selectedAccountIdx]?.number ?? false) ? (
                <span className="mt-1 text-red-500 dark:text-red-400 text-xs">
                  {(errors.registration?.tenant?.accounts as Array<FormikErrors<UtilityAccount>> | undefined)?.[values.selectedAccountIdx]?.number}
                </span>
              ) : null}
            </div>
            <div className="col-span-3 dark:text-slate-100"></div>
            <div className="col-span-6 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Billing address"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].billingAddress.address`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].billingAddress.address}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-6 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Utility account name"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].name`}
                required
                displayField={!canEditForm}
                className={`mt-1 text-sm rounded-md border-transparent ${
                  (errors.registration?.tenant?.accounts as Array<FormikErrors<UtilityAccount>> | undefined)?.[values.selectedAccountIdx]?.name !== undefined &&
                  (touched.registration?.tenant?.accounts?.[values.selectedAccountIdx]?.name ?? false)
                    ? 'bg-red-50 dark:bg-red-900 border-red-500 focus:border-red-500'
                    : 'focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white dark:focus:bg-slate-700'
                } focus:ring-0`}
                value={values.registration.tenant.accounts[values.selectedAccountIdx].name}
                disabled={isSubmitting}
              />
              {(errors.registration?.tenant?.accounts as Array<FormikErrors<UtilityAccount>> | undefined)?.[values.selectedAccountIdx]?.name !== undefined &&
              (touched.registration?.tenant?.accounts?.[values.selectedAccountIdx]?.name ?? false) ? (
                <span className="mt-1 text-red-500 dark:text-red-400 text-xs">
                  {(errors.registration?.tenant?.accounts as Array<FormikErrors<UtilityAccount>> | undefined)?.[values.selectedAccountIdx]?.name}
                </span>
              ) : null}
            </div>
            <div className="col-span-2 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="City"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].billingAddress.city`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].billingAddress.city}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-2 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="State"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].billingAddress.state`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].billingAddress.state}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-2 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Zip"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].billingAddress.zipCode`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].billingAddress.zipCode}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-12 border-b-2 overflow-x-auto overflow-y-hidden border-slate-200 dark:border-slate-600">
              <span className="pb-2 mr-4 inline-flex flex-col justify-center text-md font-medium leading-4 text-slate-900 dark:text-slate-100">
                Service Agreements:
              </span>
              {agreementOptions.map((account, index) => (
                <a
                  key={index}
                  className={classnames(
                    account.value === agreementOptions[values.selectedAgreementIdx].value
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap mb-1 mx-2 p-1 border-b-2 font-medium text-sm'
                  )}
                  onClick={() => {
                    void setFieldValue('selectedAgreementIdx', account.value)
                  }}
                >
                  SA {Number(values.selectedAccountIdx) + 1}-{index + 1}
                </a>
              ))}
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Service agreement number"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].agreements[${values.selectedAgreementIdx}].number`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].agreements[values.selectedAgreementIdx].number}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Meter number"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].agreements[${values.selectedAgreementIdx}].meterNumber`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].agreements[values.selectedAgreementIdx].meterNumber}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-6 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Service address"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].agreements[${values.selectedAgreementIdx}].serviceAddress.address`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].agreements[values.selectedAgreementIdx].serviceAddress.address}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-3 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Tariff schedule"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].agreements[${values.selectedAgreementIdx}].tariffSchedule`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].agreements[values.selectedAgreementIdx].tariffSchedule}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-3 dark:text-slate-100"></div>
            <div className="col-span-2 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="City"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].agreements[${values.selectedAgreementIdx}].serviceAddress.city`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].agreements[values.selectedAgreementIdx].serviceAddress.city}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-2 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="State"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].agreements[${values.selectedAgreementIdx}].serviceAddress.state`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].agreements[values.selectedAgreementIdx].serviceAddress.state}
                disabled={isSubmitting}
              />
            </div>
            <div className="col-span-2 flex flex-col text-slate-900 dark:text-slate-100">
              <Field
                as={Input}
                label="Zip"
                name={`registration.tenant.accounts[${values.selectedAccountIdx}].agreements[${values.selectedAgreementIdx}].serviceAddress.zipCode`}
                displayField={!canEditForm}
                className="mt-1 text-sm rounded-md border-transparent focus:border-slate-500 dark:focus:border-slate-400 focus:bg-white"
                value={values.registration.tenant.accounts[values.selectedAccountIdx].agreements[values.selectedAgreementIdx].serviceAddress.zipCode}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="text-slate-900 dark:text-slate-100"> </div>

          {agreementSubmissions.length > 0 && tesaSupplements.length > 0 && (
            <>
              <div className="my-2 py-2 w-full text-md font-medium leading-4 border-b-2 border-slate-200 dark:border-slate-600">
                Utility Authorization Agreements
              </div>
              <div className="grid grid-cols-[auto_auto_auto_auto] gap-2 items-center text-sm">
                <div className="font-medium">Status</div>
                <div className="font-medium">Timestamp</div>
                <div className="font-medium">Reference ID</div>
                <div className="font-medium">Actions</div>
                {tesaSupplements.map((as: AgreementSubmission, index: number) => (
                  <div className="contents" key={index}>
                    <div className="h-4">
                      {as.status === 'SUBMITTED' && as.signatureSource !== 'EXTERNAL' && 'Submitted to utility'}
                      {as.status === 'SUBMITTED' && as.signatureSource === 'EXTERNAL' && 'Uploaded document'}
                      {as.status === 'ACCEPTED' && 'Accepted on behalf of utility'}
                      {as.status === 'REJECTED' && 'Rejected on behalf of utility'}
                    </div>
                    <div>{format(new Date(as.statusDate), "M/d/yy 'at' h:mm aaa")}</div>
                    <div>{as.referenceId.split('-')[0]}</div>
                    <div className="flex flex-row">
                      <Link to={`/tenant/onboarding/TESAsupplement/${as.agreementId}`} target="_blank" rel="noopener noreferrer" tabIndex={-1} className="mr-2">
                        <Button size={1} color="slate" className="mr-2" disabled={isSubmitting} tabIndex={-1}>
                          <span>View document</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 inline align-text-bottom ml-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                        </Button>
                      </Link>
                      {as.status === 'SUBMITTED' && (
                        <>
                          <Button
                            size={1}
                            color="emerald"
                            className="mr-2"
                            disabled={isSubmitting}
                            tabIndex={-1}
                            onClick={async () => {
                              try {
                                await updateTESASupplementAgreementStatusApi(as.agreementId, 'ACCEPTED')
                                await props.refreshList()
                                dispatch(
                                  pushToast({
                                    type: 'success',
                                    message: 'Utility authorization agreement accepted on behalf of utility',
                                  })
                                )
                              } catch (error: any) {
                                dispatch(
                                  pushToast({
                                    type: 'error',
                                    message: 'Failed to accept utility authorization agreement',
                                    description: error.response?.data?.message ?? error.response?.message ?? error.message,
                                  })
                                )
                              }
                            }}
                          >
                            <span>Accept</span>
                          </Button>
                          <Button
                            size={1}
                            color="red"
                            className="mr-2"
                            disabled={isSubmitting}
                            tabIndex={-1}
                            onClick={async () => {
                              try {
                                await updateTESASupplementAgreementStatusApi(as.agreementId, 'REJECTED')
                                await props.refreshList()
                                dispatch(
                                  pushToast({
                                    type: 'success',
                                    message: 'Utility authorization agreement rejected on behalf of utility',
                                  })
                                )
                              } catch (error: any) {
                                dispatch(
                                  pushToast({
                                    type: 'error',
                                    message: 'Failed to reject utility authorization agreement',
                                    description: error.response?.data?.message ?? error.response?.message ?? error.message,
                                  })
                                )
                              }
                            }}
                          >
                            <span>Reject</span>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {externallySingedAgreementDocuments.length > 0 && (
            <>
              <div className="my-2 py-2 w-full text-md font-medium leading-4 border-b-2 border-slate-200 dark:border-slate-600">
                Externally Signed Agreement Documents
              </div>
              <div className="grid grid-cols-[auto_auto_auto] gap-2 items-center text-sm">
                <div className="font-medium">Type</div>
                <div className="font-medium">Date Signed</div>
                <div className="font-medium">Actions</div>
                {externallySingedAgreementDocuments.map((doc: ExternallySignedAgreementDocument, index: number) => (
                  <div className="contents" key={index}>
                    <div className="h-4">
                      {doc.type === 'TESA_SUPPLEMENT' ? 'Utility Authorization' : formatTitleCase(doc.type).replace(new RegExp('Tesa', 'g'), 'TESA')}
                    </div>
                    <div>{formatUTCDate(doc.dateSigned)}</div>
                    <div className="flex flex-row">
                      <Link
                        to={`/tenant/onboarding/${doc.type === 'TESA_SUPPLEMENT' ? 'TESAsupplement' : 'TESA'}/${doc.agreementId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        tabIndex={-1}
                        className="mr-2"
                      >
                        <Button size={1} color="slate" className="mr-2" disabled={isSubmitting} tabIndex={-1}>
                          <span>View document</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 inline align-text-bottom ml-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                        </Button>
                      </Link>
                      <div>
                        <Button
                          size={1}
                          color="slate"
                          className="mr-2"
                          disabled={isSubmitting}
                          tabIndex={-1}
                          onClick={() => {
                            setShowAgreementDocTypeOptions(false)
                            setSelectedAgreementDocRefIndex(index)
                            setAgreementDocType({ label: doc.type === 'TESA_SUPPLEMENT' ? 'Utility Authorization' : 'TESA', value: doc.type })
                            handleUploadClick(false)
                          }}
                        >
                          <span>Change document...</span>
                        </Button>
                        <input
                          type="file"
                          // Assign this input's ref to be the ref in the array of refs at the current index of the document array
                          ref={(el) => {
                            if (el != null) {
                              changeDocInputRefs.current[index] = el
                            }
                          }}
                          onChange={(event) => handleFileChange(event, false, doc.type, doc.agreementId)}
                          style={{ display: 'none' }} // Hide the file input
                          accept="application/pdf" // Accept only PDF files
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {props.onboarding.utility === 'PG&E' ? (
            <div className="mt-6 space-y-5">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <Field
                    name="registration.tenant.authorizeSpecificAccountsOnly"
                    type="checkbox"
                    id="authorizeSpecificAccountsOnly"
                    checked={values.registration.tenant.authorizeSpecificAccountsOnly ?? false}
                    disabled={isAccountInviteConsumed || isSubmitting}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="authorizeSpecificAccountsOnly" className="font-medium text-gray-900 dark:text-gray-100">
                    Authorize specific accounts only
                    <p id="comments-description" className="text-gray-500 dark:text-gray-400 text-xs">
                      Forces PMA form option &ldquo;Authorize management of specific accounts only&rdquo;
                      <br />
                      instead of &ldquo;Authorize management of ALL EXISTING AND FUTURE accounts&rdquo;
                    </p>
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          <div className="mt-6"></div>
          <div className="-mx-6 p-6 bg-slate-50 dark:bg-slate-900 flex rounded-b-2xl">
            <div className="flex flex-1 justify-start">
              <div className="mr-4">
                <Button
                  onClick={() => {
                    setShowAgreementDocTypeOptions(true)
                    handleUploadClick(true)
                  }}
                >
                  Upload externally signed document...
                </Button>
                <input
                  type="file"
                  ref={uploadNewDocInputRef}
                  onChange={(event) => handleFileChange(event, true, agreementDocType.value)}
                  style={{ display: 'none' }} // Hide the file input
                  accept="application/pdf" // Accept only PDF files
                />
                {
                  <CustomContentModal isOpen={showUploadDateModal} onClose={() => {}}>
                    <div className="mb-3 bg-slate-100 dark:bg-slate-800 p-3 rounded-lg">
                      {showAgreementDocTypeOptions && (
                        <div className="mb-3">
                          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Select a type of agreement document to upload</h3>
                          <Select
                            selected={agreementDocType}
                            options={
                              externallySignedTesas.length < 1
                                ? [
                                    { label: 'TESA', value: 'TESA' },
                                    { label: 'Utility Authorization', value: 'TESA_SUPPLEMENT' },
                                  ]
                                : [{ label: 'Utility Authorization', value: 'TESA_SUPPLEMENT' }]
                            }
                            onChange={(selected) => {
                              setAgreementDocType(selected)
                            }}
                          />
                        </div>
                      )}
                      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
                        Select a signed date <br />
                        to use for this {agreementDocType.label}
                      </h3>
                      <div className="mt-6">
                        <Datepicker
                          useRange={false}
                          asSingle={true}
                          value={uploadedDate}
                          onChange={(date: any) => {
                            setUploadedDate(date)
                          }}
                          primaryColor={'indigo'}
                          containerClassName={'text-base font-base text-inherit relative'}
                          inputClassName={classnames(
                            'mt-1 px-2 inline-block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-gray-100 dark:bg-slate-800'
                          )}
                          popoverDirection="down"
                          displayFormat={'MM/DD/YYYY'}
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="flex justify-end mt-4">
                        <Button
                          onClick={() => {
                            setShowUploadDateModal(false)
                          }}
                          type="button"
                          color="slate"
                          className="w-28"
                        >
                          Close
                        </Button>
                        <Button
                          onClick={() => {
                            setShowUploadDateModal(false)
                            if (newUpload === true && uploadNewDocInputRef.current != null) {
                              uploadNewDocInputRef.current.click()
                            } else if (newUpload === false && changeDocInputRefs.current != null && selectedAgreementDocRefIndex != null) {
                              changeDocInputRefs.current[selectedAgreementDocRefIndex].click()
                            }
                          }}
                          type="button"
                          color="orange"
                          className="w-28 ml-2"
                        >
                          Upload document...
                        </Button>
                      </div>
                    </div>
                  </CustomContentModal>
                }
              </div>
              {values.registration.tenantId !== undefined && (
                <Link to={`/tenant/tenants/${values.registration.tenantId}`} target="_blank" rel="noopener noreferrer" tabIndex={-1}>
                  <Button className="mr-4" disabled={isSubmitting} tabIndex={-1}>
                    <span>View tenant record</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 inline align-text-bottom ml-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                  </Button>
                </Link>
              )}
              {(isOnregInviteCreated || isOnregInvitePending || agreementSubmissions[0]?.status === 'REJECTED') && props.onboarding.isComplete !== false && (
                <CopyToClipboard
                  tabIndex={-1}
                  className="mr-4"
                  textToCopy={`${window.location.host !== 'admin.kingenergy.com' ? 'dev.' : ''}registration.kingenergy.com/${ONBOARDING_REGISTRATION.code}`}
                >
                  Copy registration invite
                </CopyToClipboard>
              )}
              {isOnregInviteConsumed && !isAccountInviteCreated && agreementSubmissions[0]?.status !== 'REJECTED' && (
                <Button
                  className="mr-4"
                  disabled={isFetchingApi}
                  tabIndex={-1}
                  onClick={async () => {
                    setFetchingApi(true)
                    try {
                      await generateAccountInvitationApi([ONBOARDING_REGISTRATION.id])
                      await props.refreshList()
                      dispatch(
                        pushToast({
                          type: 'success',
                          message: 'Account invitation generated',
                        })
                      )
                      setFetchingApi(false)
                    } catch (error: any) {
                      dispatch(
                        pushToast({
                          type: 'error',
                          message: 'Failed to generate account invitation',
                          description: error.response?.data?.message ?? error.response?.message ?? error.message,
                        })
                      )
                      setFetchingApi(false)
                    }
                  }}
                >
                  Generate account invite
                </Button>
              )}
              {isAccountInviteCreated && !isAccountInviteConsumed && agreementSubmissions[0]?.status !== 'REJECTED' && (
                <CopyToClipboard
                  tabIndex={-1}
                  className="mr-4"
                  textToCopy={`${window.location.host !== 'admin.kingenergy.com' ? 'dev.' : ''}service.kingenergy.com/register?invitationCode=${
                    ACCOUNT_SIGNUP?.code
                  }`}
                >
                  Copy account invite
                </CopyToClipboard>
              )}
              {!isOnregInviteConsumed && props.onboarding.isNational === true ? (
                <Button
                  type="button"
                  disabled={isSubmitting}
                  tabIndex={-1}
                  onClick={async () => {
                    setFetchingApi(true)
                    try {
                      await markRegistrationCompleteApi(props.onboarding.id)
                      await props.refreshList()
                      dispatch(
                        pushToast({
                          type: 'success',
                          message: 'Registration marked complete',
                        })
                      )
                      setFetchingApi(false)
                    } catch (error: any) {
                      dispatch(
                        pushToast({
                          type: 'error',
                          message: 'Failed to mark registration complete',
                          description: error.response?.data?.message ?? error.response?.message ?? error.message,
                        })
                      )
                      setFetchingApi(false)
                    }
                  }}
                >
                  Mark registration complete
                </Button>
              ) : null}
            </div>
            <div className="flex flex-1 justify-end">
              <Button type="button" disabled={isSubmitting} onClick={props.closeFn} tabIndex={-1} color="slate" className="w-28">
                Close
              </Button>
              {canEditForm && (
                <Button type="submit" disabled={isSubmitting} tabIndex={-1} color="orange" className="w-28 ml-2">
                  {isSubmitting && (
                    <svg
                      className="animate-spin inline -mt-1 -ml-1 mr-3 h-5 w-5 text-orange-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Save
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}
