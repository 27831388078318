import React from 'react'
import { ServiceAgreement } from '../../../../apis/types'
import Table from '../../../../components/Table/Table'
import InlineEditInput from '../../../../components/Form/InlineEditInput'
import { formatUTCDate } from '../../../../util/string'
import { Button } from '../../../../components/Form/Button'
import { Link } from 'react-router-dom'
import { updateMeterApi } from '../../../../apis/apis'
import { useAppDispatch } from '../../../../shared/redux/hooks'
import { getTenant } from '../Tenants.slice'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

interface ServiceAgreementWithAccountNumber extends ServiceAgreement {
  tenantAccountNumber: string
}
interface TabServiceAgreementsProps {
  title: string
  data: ServiceAgreementWithAccountNumber[]
  hasSalesforceOpsROPermission: () => boolean
  hasSalesforceOpsRWPermission: () => boolean
}

const TabServiceAgreements: React.FC<TabServiceAgreementsProps> = (props) => {
  const { data, hasSalesforceOpsROPermission, hasSalesforceOpsRWPermission, ...rest } = props
  const dispatch = useAppDispatch()
  const { tenantId } = useParams()

  return data.length > 0 ? (
    <Table<ServiceAgreementWithAccountNumber>
      colConfig={[
        {
          label: 'Service agreement ID',
          render: (record) => record.serviceAgreementId,
        },
        {
          label: 'Service address',
          render: (record) => {
            const sAdd = record.serviceAddress

            return (
              <span>
                {sAdd.street1} {sAdd.street2}
                <br />
                {sAdd.city}, {sAdd.state} {sAdd.zip}
              </span>
            )
          },
        },
        {
          label: 'Meter number',
          render: (record) => record.meter.meterNumber,
        },
        ...(hasSalesforceOpsROPermission()
          ? [
              {
                label: 'Salesforce Meter ID',
                /* If the user has Salesforce OPS RW permissions, display an editable link field, if they just have Salesforce OPS RO 
                   permissions, just display a link field, otherwise, display "Not set" */
                render: (record: ServiceAgreementWithAccountNumber) => {
                  if (hasSalesforceOpsRWPermission()) {
                    return (
                      <InlineEditInput
                        name="salesforceMeterId"
                        label="Salesforce Meter ID"
                        hideLabel
                        required
                        initialValue={record.meter?.salesforceMeterId === 'N/A' ? '' : record.meter?.salesforceMeterId}
                        handleSubmit={async (editedValue) => {
                          await updateMeterApi(record.meter.cuid as string, { salesforceId: editedValue }).then(() => {
                            dispatch(getTenant(tenantId as string))
                          })
                        }}
                        validationSchema={Yup.string().matches(/^(?:[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})$/, 'Must be 15 or 18 characters')}
                        className="font-medium"
                        linkTemplate="https://kingenergy.lightning.force.com/lightning/r/meter_data__c/@@value@@/view"
                      />
                    )
                  }

                  if (record.meter.salesforceMeterId != null && record.meter.salesforceMeterId !== 'N/A') {
                    return (
                      <Link
                        target="_blank"
                        className="underline dark:underline"
                        to={`https://kingenergy.lightning.force.com/lightning/r/meter_data__c/${record.meter.salesforceMeterId}/view`}
                      >
                        {record.meter.salesforceMeterId}
                      </Link>
                    )
                  }

                  return 'Not set'
                },
              },
            ]
          : []),
        {
          label: 'Tariff',
          render: (record) => (
            <span>
              {record.tariff.schedule} ({record.tariff.name})
            </span>
          ),
        },
        {
          label: 'Service period',
          render: (record) => (
            <span>
              Start: {(record.startDate !== undefined && formatUTCDate(record.startDate)) || 'Not set'}
              <br />
              End: {(record.endDate !== undefined && formatUTCDate(record.endDate)) || 'Not set'}
            </span>
          ),
        },
        {
          label: 'Actions',
          render: (record) =>
            record.meter.greenButtonEnrolled ? (
              <Link
                to={`https://kingenergy.retool.com/app/tariff-modeling/?_environment=${
                  window.location.hostname !== 'admin.kingenergy.com' ? 'staging' : 'production'
                }&tenantAccountNumber=${record.tenantAccountNumber}&meterNumber=${record.meter.meterNumber}`}
                target="_blank"
              >
                <Button>Tariff Modeling...</Button>
              </Link>
            ) : null,
        },
      ]}
      hidePaginator
      data={{
        results: data,
      }}
      {...rest}
    />
  ) : (
    <span>No service agreements found</span>
  )
}

export default TabServiceAgreements
