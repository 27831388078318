import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import classnames from "classnames";

export interface ToastProps {
  data: {
    type?: string;
    message?: string;
    description?: string;
  };
}

export const Toast: React.FC<ToastProps> = (props) => {
  const { type, message, description } = props.data;
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  /* 
  Temporary fix to override the styles that the date-picker component is overriding through inline styling
  See git hub issue: https://github.com/onesine/react-tailwindcss-datepicker/issues/171 
  */
  const tailwindColorMap: { [key: string]: string } = {
    success: "#10B981", // Tailwind's emerald-500 color
    error: "#EF4444", // Tailwind's red-500 color
    default: "#10B981", // Tailwind's emerald-500 color
  };

  const getBackgroundColor = (): string => {
    if (type != null && type in tailwindColorMap) {
      return tailwindColorMap[type as keyof typeof tailwindColorMap];
    }
    return tailwindColorMap.default;
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        style={{ backgroundColor: getBackgroundColor() }}
        className={classnames(
          'z-1000 max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-5 overflow-hidden'
        )}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-semibold text-white">{message}</p>
              {description !== undefined && <p className="text-xs font-normal text-white">{description}</p>}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
};

export default Toast;
