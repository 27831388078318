import React, { Fragment, useState, useRef, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";

interface PropTypes {
  className?: string;
  data: Array<{
    id: string;
    name: string;
    description?: string;
  }>;
  value: string | undefined;
  handleChange: (value: string | undefined) => void;
  label?: string;
  labelPlural?: string;
  dropDownZIndex?: string;
  wildCardDisabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  isNotFilter?: boolean;
  disabled?: boolean;
}

export const ComboboxAutocomplete: React.FC<PropTypes> = (props) => {
  const {
    data,
    value,
    label = "item",
    handleChange,
    labelPlural = "items",
    dropDownZIndex = "z-10",
    wildCardDisabled = false,
    autoFocus,
    placeholder,
    isNotFilter = false,
    disabled,
  } = props;
  const inputEl = useRef<HTMLInputElement>(null);

  // Allow the input to be focused on mount if autoFocus is true
  useEffect(() => {
    if ((autoFocus ?? false) && inputEl.current != null) {
      inputEl.current.focus();
    }
  }, [autoFocus]);

  const wildcardItem = {
    id: null,
    name: `All ${labelPlural.toLowerCase()}`,
    description: `Select all ${labelPlural.toLowerCase()}`,
  };
  // Check to see if the wildcard is disabled, if so, don't add it to the list
  const dataWillWildcardItem = wildCardDisabled
    ? data
    : [wildcardItem, ...data];

  const [query, setQuery] = useState<string | null>(null);

  // If the wildcard is disabled, set the selected item to the first item in the list or an empty string
  // If the wildcard is enabled, set the selected item to the wildcard item or an empty string
  const selected = wildCardDisabled
    ? dataWillWildcardItem.filter((item) => item.id === value)[0] ?? ""
    : dataWillWildcardItem.filter((item) => item.id === value)[0] ??
      wildcardItem;
  const filteredItems =
    query === ""
      ? dataWillWildcardItem
      : dataWillWildcardItem.filter((item) =>
          item.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(
              query != null ? query.toLowerCase().replace(/\s+/g, "") : ""
            )
        );

  return (
    <div>
      <Combobox
        value={selected}
        onChange={(option) => {
          setQuery(null)
          return handleChange(option?.id ?? undefined)
        }}
        disabled={disabled}
      >
        <div className={classnames('relative mt-1', dropDownZIndex)}>
          <div>
            <Combobox.Input
              className={classnames(
                // Base classes
                'mt-1 px-2 block w-full rounded-md border-0 py-1.5',
                'text-gray-900 dark:text-gray-100',
                'shadow-sm ring-1 ring-gray-300 dark:ring-gray-600',
                'placeholder:text-gray-400 dark:placeholder:text-gray-500',
                'focus:ring-0 focus:ring-inset focus:ring-indigo-600 dark:focus:ring-indigo-500',
                'sm:text-sm sm:leading-6',

                // Conditional classes based on disabled and selected state
                disabled === true
                  ? 'bg-gray-100 dark:bg-gray-800 border-gray-300 dark:border-gray-600 cursor-not-allowed focus:ring-gray-300 dark:focus:ring-gray-600 focus:border-gray-300 dark:focus:border-gray-600'
                  : [
                      // When not disabled, apply either selected or default styling
                      selected?.id !== null && !isNotFilter
                        ? 'bg-indigo-100 dark:bg-indigo-900/70 border-indigo-300 dark:border-indigo-700'
                        : 'bg-white dark:bg-gray-950',
                      'focus:ring-indigo-500 focus:border-indigo-500',
                    ]
              )}
              // If the combo box is being used as a filter, display the selected item's name in the input
              // Otherwise display an empty string, which will display the placeholder
              displayValue={() => (isNotFilter ? (query === null ? selected.name : '') : query == null ? selected.name : query)}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
              ref={inputEl}
              // If there is a placeholder prop, use that, otherwise create a placeholder based on the label
              placeholder={placeholder ?? 'Search for a(n) ' + label + '...'}
            />
            <Combobox.Button
              className={classnames(
                'absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none border-0',
                disabled === true ? 'cursor-not-allowed' : ''
              )}
            >
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery(null)}>
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 pl-0 text-base shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-gray-300">{`No ${labelPlural.toLowerCase()} found`}</div>
              ) : (
                filteredItems.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900 dark:text-gray-100'}`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{item.name}</span>
                        {selected ? (
                          <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-indigo-600'}`}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                        {item.description != null && item.description !== '' && (
                          <span className={`text-xs ${selected ? 'font-medium' : 'font-normal'} overflow-auto w-60 whitespace-normal break-words`}>
                            {item.description}
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
};

export default ComboboxAutocomplete;
