import React from 'react'
import classnames from 'classnames'

export interface BadgeProps {
  status?: string
  className?: string
}

export const Badge: React.FC<BadgeProps> = (props) => {
  const { status, className, ...rest } = props

  const defaultStatusColorMap: { [key: string]: string[] } = {
    grey: ['VOID', 'CREDITED', 'SCHEDULED'],
    red: ['FAILED', 'PAST_DUE', 'CHARGEBACK', 'CANCELLED', 'DISABLED', 'BALANCE_CARRY_FORWARD', 'NO', 'LATE', 'UNPAID'],
    yellow: ['PENDING', 'PROCESSING', 'PARTIALLY_PAID'],
    green: ['SUCCEEDED', 'PAID', 'PAID_EXTERNALLY', 'CONSUMED', 'ENABLED', 'CURRENT', 'ACTIVE', 'YES'],
  }

  const getColorClasses = (): string => {
    for (const color in defaultStatusColorMap) {
      if (status != null && defaultStatusColorMap[color].includes(status)) {
        switch (color) {
          case 'grey':
            return 'bg-grey-50 dark:bg-grey-400/10 text-grey-800 dark:text-grey-400 ring-grey-600/10 dark:ring-grey-400/20'
          case 'red':
            return 'bg-red-50 dark:bg-red-400/10 text-red-800 dark:text-red-400 ring-red-600/10 dark:ring-red-400/20'
          case 'yellow':
            return 'bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-400 ring-yellow-600/10 dark:ring-yellow-400/20'
          case 'green':
            return 'bg-green-50 dark:bg-green-400/10 text-green-800 dark:text-green-400 ring-green-600/10 dark:ring-green-400/20'
        }
      }
    }
    return 'bg-gray-50 dark:bg-gray-400/10 text-gray-800 dark:text-gray-400 ring-gray-600/10 dark:ring-gray-400/20'
  }

  return (
    <>
      <span
        className={classnames('inline-flex items-center px-2 py-1 ring-1 ring-inset rounded-full text-xs font-medium', getColorClasses(), className)}
        {...rest}
      >
        {status?.replaceAll('_', ' ')}
      </span>
    </>
  )
}

export default Badge
