import React from "react";
import classnames from "classnames";
import Paginator from "./Paginator";

import type { Paginated } from "../../apis/types";
// import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/mini'

export interface TableProps<Element extends { id: string }> {
  colConfig: Array<{
    label?: React.ReactElement | string | null;
    isHidden?: boolean;
    render: (value: Element) => React.ReactNode;
    renderHeader?: () => React.ReactNode;
    isSortable?: boolean;
    value?: string;
    contentClassName?: string;
  }>;
  data: Paginated<Element>;
  isLoading?: boolean;
  isSelected?: (value: Element) => boolean;
  hidePaginator?: boolean;
  handleClick?: (value: Element) => void;
  handlePaginatorClick?: (page: number) => void;
  onSort?: (columnLabel: string, sortDirection: string) => void;
  sortingStatus?: Map<string, string | null>;
  hideHeaders?: boolean;
}

export function Table<Element extends { id: string }>(
  props: TableProps<Element>
): React.ReactElement | null {
  const {
    colConfig,
    data: { results = [], ...rest },
    isLoading,
    handleClick,
    hidePaginator,
    onSort,
    sortingStatus,
    hideHeaders,
  } = props

  const recs = results;

  if (isLoading ?? false) {
    return <div className="text-sm font-medium dark:text-white">Loading...</div>
  }

  if (recs?.length === 0) {
    return <div className="text-sm font-medium dark:text-white">No results found</div>
  }

  const headers = colConfig.map((col, index) => {
    if (col.isHidden ?? false) {
      return null
    }

    // If a column is sortable and the sorting status is defined, set the sorting status of the column
    // Otherwise set default values
    let columnSortingStatus: string | undefined | null = 'NONE'

    // Cycle through the sorting status of each column based on its current status
    const handleColumnHeaderClick = (valueToSort: string | undefined): void => {
      if (onSort !== undefined && sortingStatus !== undefined) {
        columnSortingStatus = sortingStatus.get(valueToSort ?? '')

        if (columnSortingStatus === 'ASC') {
          onSort(valueToSort ?? '', 'DESC')
        } else if (columnSortingStatus === 'DESC') {
          onSort(valueToSort ?? '', 'NONE')
        } else {
          onSort(valueToSort ?? '', 'ASC')
        }
      }
    }

    // Handle parsing for first column which has two headers
    const headerLabelsList = col.label?.toString().split('/') ?? []
    const headerValuesList = col.value?.toString().split('/') ?? []

    return (
      <th key={index} scope="col" className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider dark:text-white">
        {/* If a column is sortable render the appropriate chevron icon based on its sorting status */}
        {onSort != null && sortingStatus != null && col.isSortable === true ? (
          <>
            <button onClick={() => handleColumnHeaderClick(headerValuesList[0])} className="flex items-center">
              <span className="text-left text-xs font-medium uppercase tracking-wider mr-2">{headerLabelsList[0] ?? null}</span>
              {sortingStatus.get(headerValuesList[0] ?? '') === 'ASC' && (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                  <path
                    fillRule="evenodd"
                    d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              {sortingStatus.get(headerValuesList[0] ?? '') === 'DESC' && (
                // Chevron down icon from Heroicon's mini icons set
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
            {/* Special case for first column of FileExplorer table which stores both a name and directory */}
            {headerLabelsList.length >= 2 && headerValuesList.length >= 2 && (
              <button onClick={() => handleColumnHeaderClick(headerValuesList[1])} className="flex items-center">
                <span className="text-left text-xs font-medium uppercase tracking-wider mr-2">{headerLabelsList[1] ?? null}</span>
                {sortingStatus.get(headerValuesList[1] ?? '') === 'ASC' && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path
                      fillRule="evenodd"
                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                {sortingStatus.get(headerValuesList[1] ?? '') === 'DESC' && (
                  // Chevron down icon from Heroicon's mini icons set
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            )}
          </>
        ) : (
          <>
            {col.label ?? null}
            {col.renderHeader?.() ?? null}
          </>
        )}
      </th>
    )
  })

  const records =
    recs?.length > 0 &&
    recs.map((record, recIndex) => (
      <tr key={recIndex} className="bg-slate-50 dark:bg-slate-950/50">
        {colConfig.map((col, colIndex) => {
          if (col.isHidden ?? false) {
            return null
          }
          return (
            <td
              className={classnames('p-4 whitespace-nowrap text-sm font-base ', {
                'cursor-pointer': handleClick,
              })}
              key={`${record.id}-${colIndex}`}
              onClick={() => handleClick?.(record)}
            >
              <div className={col.contentClassName}>{col.render(record)}</div>
            </td>
          )
        })}
      </tr>
    ))

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-slate-200 dark:divide-slate-700">
          {hideHeaders !== true && (
            <thead className="bg-white dark:bg-slate-950">
              <tr>{headers}</tr>
            </thead>
          )}
          <tbody className="divide-y divide-slate-200 dark:divide-slate-700">{records}</tbody>
        </table>
      </div>
      {hidePaginator !== true && <Paginator data={rest} handlePaginatorClick={props.handlePaginatorClick} />}
    </>
  )
}

export default Table;
