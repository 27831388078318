import React from "react";

import { useTransition, animated } from "react-spring";
import classnames from "classnames";

import { Dialog } from "@headlessui/react";

export interface CustomContentModalProps {
  isOpen: boolean;
  className?: string;
  onClose: any;
  children?: React.ReactNode;
}

export const CustomContentModal: React.FC<CustomContentModalProps> = (
  props
) => {
  const config = {
    mass: 1,
    tension: 160,
    friction: 20,
    clamp: true,
  };

  const dialog = useTransition(props.isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config,
  });

  const content = useTransition(props.isOpen, {
    from: { opacity: 0, transform: "scale(0.95)" },
    enter: { opacity: 1, transform: "scale(1.00)" },
    leave: { opacity: 0, transform: "scale(0.95)" },
    config,
  });

  return dialog(
    (styles, item) =>
      item && (
        <Dialog static open={props.isOpen} onClose={props.onClose} as={animated.div} className="fixed h-screen inset-0 z-10" style={styles}>
          <div className="h-screen overflow-y-scroll text-center bg-black bg-opacity-50 dark:bg-opacity-70">
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            {content(
              (styles, item) =>
                item && (
                  <animated.div
                    className={classnames('w-full h-min relative py-8 inline-flex justify-center align-middle transition-all z-40 transform', props.className)}
                    style={styles}
                  >
                    {props.children}
                  </animated.div>
                )
            )}
          </div>
        </Dialog>
      )
  )
};
