import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import classnames from "classnames";

export interface OnboardingStepProps {
  title: string;
  timestamp?: string;
  showTimestamp: boolean;
  showChildren?: boolean;
  isComplete?: boolean;
  isActive?: boolean;
  isIncomplete?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const OnboardingStep: React.FC<OnboardingStepProps> = (props) => {
  const {
    title,
    timestamp,
    showTimestamp,
    showChildren = false,
    children,
    className,
    isComplete = false,
    isActive = false,
    isIncomplete = false,
  } = props;

  return (
    <div className={classnames('flex flex-col justify-top text-slate-700 dark:text-slate-300 last:mb-0', className)}>
      <div className="flex justify-center">
        <div
          className={classnames('w-5 h-5 mb-1 flex flex-col justify-center border border-transparent rounded-full text-center text-sm font-medium', {
            'bg-indigo-500 text-white': isComplete,
            'bg-indigo-900 dark:bg-indigo-600 text-white': isActive,
            'bg-white dark:bg-slate-800 text-indigo-500 dark:text-indigo-400 border-indigo-600 dark:border-indigo-500 outline-1 outline-dashed': isIncomplete,
          })}
        >
          {(isComplete || isActive) && <CheckIcon className="h-3 w-3 text-white flex self-center" />}
        </div>
      </div>
      <div className="text-center">
        <div
          className={classnames('text-sm mb-1', {
            'font-medium': isComplete,
            'font-semibold': isActive,
            'font-normal': isIncomplete,
          })}
        >
          {title}
        </div>
      </div>
      <div className="text-center flex-1 flex flex-col justify-end">
        {timestamp !== undefined && showTimestamp && (
          <div className="text-slate-500 dark:text-slate-400 text-xs">{format(new Date(timestamp), "M/d/yy 'at' h:mm aaa")}</div>
        )}
      </div>
      <div className="text-center">{(isActive || showChildren) && children}</div>
    </div>
  )
};

export default OnboardingStep;
