import React, { useState, useEffect } from "react";
import { IncentivePlan, IncentivePlans, Installment, IncentivePlanOptionsResult, BillingSdkTenantAccountBalances } from '../../../../apis/types'
import { getTenantAccountBalancesFromBillingSdkApi } from '../../../../apis/apis'
import IncentivePlanDetailsDisclosure from "./IncentivePlanDetailsDisclosure";
import Panel from "../../../../components/Panel";
import {
  formatTitleCase,
  formatUTCDetailedDate,
} from "../../../../util/string";
import Badge from "../../../../components/Badge";
import DisplayField from "../../../../components/Form/DisplayField";
import Table from "../../../../components/Table/Table";
import Button from "../../../../components/Form/Button";
import { CustomContentModal as FormModal } from "../../../../components/CustomContentModal";
import CreateIncentivePlanForm from "./CreateIncentivePlanForm";
import classnames from "classnames";
import { pushToast } from '../../../../components/Toaster/Toaster.slice'
import VNEMWarning from '../../../programs/components/VNEMWarning'
import { useAppDispatch, useAppSelector } from "../../../../shared/redux/hooks";
interface TabIncentivePlansProps {
  title: string;
  data: IncentivePlans;
  tenantAccountId: string;
  incentivePlanOptions: IncentivePlanOptionsResult | undefined;
  latestKingInvoiceDueDate: string | undefined;
}

const TabIncentivePlans: React.FC<TabIncentivePlansProps> = ({
  data,
  tenantAccountId,
  incentivePlanOptions,
  latestKingInvoiceDueDate,
}) => {
  const { permissions } = useAppSelector((state) => state.app)
  const [showFormModal, setShowFormModal] = React.useState(false)
  const dispatch = useAppDispatch()

  const [kingOnlyBalance, setKingOnlyBalance] = useState<number | undefined>(undefined)
  const [estimatedUtilityBalance, setEstimatedUtilityBalance] = useState<number | undefined>(undefined)

  const createIncentivePlanButtonDisabled = (): boolean => {
    if (!hasCreateIncentivePlanPermission) return true
    return false
  }

  const hasCreateIncentivePlanPermission =
    permissions != null
      ? permissions.includes('account:manager:incentive-plan:modify:king') || permissions.includes('god:incentive-plan:modify:utility')
      : false

  const hasCreateUtilityIncentivePlanPermission = permissions?.includes('god:incentive-plan:modify:utility') ?? false

  useEffect(() => {
    const getTenantAccountBalancesFromBillingSdk = async (): Promise<BillingSdkTenantAccountBalances | undefined> => {
      try {
        const response = await getTenantAccountBalancesFromBillingSdkApi(tenantAccountId)
        return response
      } catch (e: any) {
        await dispatch(
          pushToast({
            message: 'Failed to get king only balance',
            type: 'error',
            description: `${e.response?.data?.error !== undefined ? `${e.response?.data?.error as string}:` : 'Error:'} ${e.message as string}`,
          })
        )
      }
    }

    getTenantAccountBalancesFromBillingSdk()
      .then((balances) => {
        if (balances != null) {
          setKingOnlyBalance(Number(balances.kingOnlyBalance))
          setEstimatedUtilityBalance(Number(balances.estimatedUtilityBalance))
        }
      })
      .catch(() => {})
  }, [dispatch, tenantAccountId])

  const renderIncentivePlanPanel = (incentivePlan: IncentivePlan, type: 'King' | 'Utility', defaultOpen: boolean): JSX.Element => {
    const title = `${type} ${formatTitleCase(incentivePlan.cadence)} ${formatTitleCase(incentivePlan.type)}`
    const numInstallmentsPaidOrCredited = incentivePlan.installments.filter((installment) =>
      incentivePlan.type === 'ACCOMMODATION_CREDIT' ? installment.status === 'CREDITED' : installment.status === 'PAID'
    ).length

    return (
      <Panel
        key={incentivePlan.id}
        header={
          <div className="flex justify-between w-full">
            <div>
              <div>{title}</div>
            </div>
            <div>
              <div>
                <Badge status={incentivePlan.status} />
              </div>
            </div>
          </div>
        }
      >
        <div className="flex flex-col justify-start">
          <div className={classnames('grid gap-4 pl-2', incentivePlan.cadence === 'ONE_TIME' && incentivePlan.endDate != null ? 'grid-cols-3' : 'grid-cols-2')}>
            <DisplayField label="Start Date">{formatUTCDetailedDate(new Date(incentivePlan.startDate))}</DisplayField>
            {incentivePlan.cadence === 'ONE_TIME' && <DisplayField label="Amount Credited">{`$${incentivePlan.startingBalance}`}</DisplayField>}
            {incentivePlan.endDate != null && <DisplayField label="End Date">{formatUTCDetailedDate(new Date(incentivePlan.endDate))}</DisplayField>}
          </div>
          <div className="2xl:grid 2xl:grid-cols-2 2xl:gap-4">
            <IncentivePlanDetailsDisclosure
              label="Incentive Plan Details"
              defaultOpen={defaultOpen}
              contents={
                <div>
                  <div className="grid grid-auto-flow grid-cols-3">
                    <DisplayField
                      label={incentivePlan.type === 'ACCOMMODATION_CREDIT' ? 'Total Amount Credited' : 'Starting Balance'}
                    >{`$${incentivePlan.startingBalance}`}</DisplayField>
                    <DisplayField label="Remaining Balance">{`$${incentivePlan.remainingBalance}`}</DisplayField>
                    <DisplayField
                      label={incentivePlan.type === 'ACCOMMODATION_CREDIT' ? 'Installments Credited' : 'Installments Paid'}
                    >{`${numInstallmentsPaidOrCredited}/${incentivePlan.installmentCount}`}</DisplayField>
                  </div>
                  {incentivePlan.memo != null && (
                    <div className="mt-3">
                      <DisplayField label="Description">{incentivePlan.memo}</DisplayField>
                    </div>
                  )}
                  <div className="grid grid-auto-flow grid-cols-3 mt-3">
                    <DisplayField label="Created By">{incentivePlan.createdBy != null ? incentivePlan.createdBy : 'Unknown'}</DisplayField>
                    <DisplayField label="Created On">{formatUTCDetailedDate(new Date(incentivePlan.createdAt))}</DisplayField>
                    <div />
                  </div>
                </div>
              }
            />
            <IncentivePlanDetailsDisclosure
              label="Installment Schedule"
              defaultOpen={defaultOpen}
              contents={
                <div className="bg-white dark:bg-gray-800">
                  <Table<Installment>
                    data={{ results: incentivePlan.installments }}
                    colConfig={[
                      {
                        label: 'Installment',
                        render: (record) => record.number,
                      },
                      {
                        label: incentivePlan.type === 'ACCOMMODATION_CREDIT' ? 'Amount Credited' : 'Amount Due',
                        render: (record) => `$${record.amount}`,
                      },
                      {
                        label: 'Remaining Balance',
                        render: (record) => `$${record.remainingBalance}`,
                      },
                      {
                        label: 'Status',
                        render: (record) => <Badge status={record.status} />,
                      },
                      {
                        label: incentivePlan.type === 'ACCOMMODATION_CREDIT' ? 'Credit Date' : 'Due Date',
                        render: (record) => formatUTCDetailedDate(new Date(record.dueDate)),
                      },
                    ]}
                    hidePaginator
                  />
                </div>
              }
            />
            <div />
          </div>
        </div>
      </Panel>
    )
  }

  const renderCreateIncentivePlanButton = (): JSX.Element => {
    return (
      <Button onClick={() => setShowFormModal(true)} disabled={createIncentivePlanButtonDisabled()}>
        Create Incentive Plan...
      </Button>
    )
  }

  return (
    <>
      <div>
        <div className="px-14 2xl:px-0">
          <div className="grid grid-cols-1 gap-4 px-14">
            {data.tenantAccountIncentivePlans.length === 0 && data.utilityAccountIncentivePlans.length === 0 ? (
              <div className="flex flex-col justify-center items-center">
                <div className="mt-4 mr-3">
                  {!hasCreateIncentivePlanPermission && <VNEMWarning type="warning" text="You do not have permission to create an incentive plan" />}
                </div>
                <p className="mb-4 dark:text-gray-200">No incentive plans found for this service account</p>
                {renderCreateIncentivePlanButton()}
              </div>
            ) : (
              <>
                <div className={classnames('flex flex-row justify-between items-center')}>
                  <div className="mt-4 mr-3">
                    {!hasCreateIncentivePlanPermission && <VNEMWarning type="warning" text="You do not have permission to create an incentive plan" />}
                  </div>
                  {renderCreateIncentivePlanButton()}
                </div>
                {data.tenantAccountIncentivePlans.map((incentivePlan) => renderIncentivePlanPanel(incentivePlan, 'King', incentivePlan.status === 'ACTIVE'))}
                {data.utilityAccountIncentivePlans.map((incentivePlan) =>
                  renderIncentivePlanPanel(incentivePlan, 'Utility', incentivePlan.status === 'ACTIVE')
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <FormModal
        isOpen={showFormModal}
        onClose={() => {
          setShowFormModal(false)
        }}
      >
        <CreateIncentivePlanForm
          closeFunction={() => setShowFormModal(false)}
          tenantAccountId={tenantAccountId}
          incentivePlanOptions={incentivePlanOptions}
          latestKingInvoiceDueDate={latestKingInvoiceDueDate}
          kingOnlyBalance={kingOnlyBalance}
          estimatedUtilityBalance={estimatedUtilityBalance}
          hasCreateUtilityIncentivePlanPermission={hasCreateUtilityIncentivePlanPermission}
        />
      </FormModal>
    </>
  )
};

export default TabIncentivePlans;
