import React from 'react'

interface ActionButtonWithIconProps {
  onClick: () => void
  title: string
  icon: string
}

export const ActionButtonWithIcon: React.FC<ActionButtonWithIconProps> = ({ onClick, title, icon }) => {
  const refreshIcon: JSX.Element = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  )

  const undoIcon: JSX.Element = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
    </svg>
  )

  return (
    <button
      className="flex justify-bottom items-center px-2 py-2 text-sm font-medium text-indigo-900 dark:text-indigo-100 bg-indigo-100 dark:bg-indigo-900 disabled:text-indigo-500 dark:disabled:text-indigo-400 disabled:bg-indigo-50 dark:disabled:bg-indigo-950 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-indigo-200 dark:hover:bg-indigo-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
      onClick={onClick}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="my-0.5">
          {icon === 'refresh' ? refreshIcon : null}
          {icon === 'undo' ? undoIcon : null}
        </div>
        <span className="ml-2">{title}</span>
      </div>
    </button>
  )
}

export default ActionButtonWithIcon
