import React, { useEffect, useState } from 'react'
import { MoonIcon, SunIcon, ComputerDesktopIcon } from '@heroicons/react/24/solid'

const ThemeToggle: React.FC = () => {
  const [syncWithSystem, setSyncWithSystem] = useState(() => {
    return localStorage.getItem('syncWithSystem') !== 'false'
  })

  const [darkMode, setDarkMode] = useState(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('syncWithSystem') !== 'false') {
        return window.matchMedia('(prefers-color-scheme: dark)').matches
      }
      if (localStorage.getItem('darkMode')) {
        return localStorage.getItem('darkMode') === 'true'
      }
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  })

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const handleChange = (e: MediaQueryListEvent) => {
      if (syncWithSystem) {
        setDarkMode(e.matches)
      }
    }

    mediaQuery.addEventListener('change', handleChange)
    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [syncWithSystem])

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  return (
    <div className="flex gap-2 px-2 justify-center">
      <button
        onClick={() => {
          setSyncWithSystem(false)
          localStorage.setItem('syncWithSystem', 'false')
          setDarkMode(false)
          localStorage.setItem('darkMode', 'false')
        }}
        className={`p-2 rounded-lg text-xs ${
          !darkMode && !syncWithSystem ? 'bg-slate-100 text-indigo-400 dark:bg-slate-700' : 'dark:bg-slate-800 hover:bg-slate-100 dark:hover:bg-slate-700'
        }`}
      >
        <SunIcon className="inline-block w-4 h-4" /> Light
      </button>
      <button
        onClick={() => {
          setSyncWithSystem(false)
          localStorage.setItem('syncWithSystem', 'false')
          setDarkMode(true)
          localStorage.setItem('darkMode', 'true')
        }}
        className={`p-2 rounded-lg text-xs ${
          darkMode && !syncWithSystem ? 'bg-slate-100 text-indigo-400 dark:bg-slate-700' : 'dark:bg-slate-800 hover:bg-slate-100 dark:hover:bg-slate-700'
        }`}
      >
        <MoonIcon className="inline-block w-4 h-4" /> Dark
      </button>
      <button
        onClick={() => {
          setSyncWithSystem(true)
          localStorage.setItem('syncWithSystem', 'true')
          setDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches)
        }}
        className={`p-2 rounded-lg text-xs ${
          syncWithSystem ? 'bg-slate-100 text-indigo-400 dark:bg-slate-700' : 'dark:bg-slate-800 hover:bg-slate-100 dark:hover:bg-slate-700'
        }`}
      >
        <ComputerDesktopIcon className="inline-block w-4 h-4" /> System
      </button>
    </div>
  )
}

export default ThemeToggle
