import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const UserInfo: React.FC = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0()

  if (!isAuthenticated || isLoading) return null

  return (
    <div className="p-2 text-xs pointer-default text-slate-400 dark:text-slate-500 border-t border-slate-200 dark:border-slate-700">
      {user?.name && user?.name !== user?.email && (
        <div className="truncate font-semibold w-[170px] text-slate-500 dark:text-slate-400" title={user.name}>
          {user.name}
        </div>
      )}
      {user?.email && (
        <div className="truncate font-normal w-[170px] text-slate-500 dark:text-slate-400" title={user.email}>
          {user.email}
        </div>
      )}
      <a
        onClick={() => {
          logout({ logoutParams: { returnTo: window.location.origin } })
        }}
      >
        <div className="inline cursor-pointer font-normal pt-1 transition-color duration-200 text-slate-500 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-500 font-medium">
          Log Out
        </div>
      </a>
    </div>
  )
}

export default UserInfo
