import React from 'react'

interface IconProgramProps {
  className?: string
}

const IconProgram: React.FC<IconProgramProps> = ({ className }) => {
  return (
    <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
      <title>icon-program</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-76, -212)">
          <g id="icon-program" transform="translate(76, 212)">
            <path
              d="M18.1730139,21.920017 L24.0030139,27.750017 C25.0433011,28.7570592 26.6990148,28.7436171 27.7228144,27.7198175 C28.746614,26.6960179 28.7600561,25.0403042 27.7530139,24.000017 L21.8760139,18.123017 M18.1730139,21.920017 L20.6690139,18.890017 C20.9860139,18.506017 21.4090139,18.264017 21.8770139,18.124017 M18.1730139,21.920017 L13.5180139,27.573017 C12.9026169,28.3231604 11.9145879,28.6586855 10.9696485,28.4384174 C10.0247091,28.2181492 9.28688167,27.4803218 9.06661351,26.5353824 C8.84634534,25.590443 9.18187051,24.602414 9.93201389,23.987017 L16.7690139,18.357017 M21.8770139,18.124017 C22.4270139,17.960017 23.0400139,17.936017 23.6200139,17.984017 C25.2108809,18.1206234 26.7550065,17.4039799 27.6776652,16.1008222 C28.6003239,14.7976645 28.7633306,13.1031644 28.1060139,11.648017 L24.8300139,14.925017 C23.7117646,14.6664386 22.8385923,13.7932663 22.5800139,12.675017 L25.8560139,9.39901699 C24.4008665,8.74170031 22.7063664,8.90470696 21.4032087,9.82736566 C20.100051,10.7500244 19.3834075,12.29415 19.5200139,13.885017 C19.6110139,14.961017 19.4490139,16.149017 18.6160139,16.835017 L18.5140139,16.920017 M16.7690139,18.357017 L12.6620139,14.250017 L11.2530139,14.250017 L9.00301389,10.500017 L10.5030139,9.00001699 L14.2530139,11.250017 L14.2530139,12.659017 L18.5130139,16.919017 M16.7680139,18.356017 L18.5130139,16.919017 M25.1280139,25.125017 L22.5030139,22.500017"
              stroke="rgb(220 38 38)"
              className="stroke-red-600 dark:stroke-red-400"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle id="Oval-Copy-2-Copy" fillOpacity="0.056517701" className="fill-red-400 dark:fill-red-200" fillRule="nonzero" cx="19" cy="19" r="19" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconProgram
