import React from "react";
import { UserDetails } from "./UserDetails";
import { UpdateUserForm } from "./UpdateUserForm";
import { Modal } from "../../../components/Modal";

import type { User, Role } from "../../shared/types/models";
import type { UpdateUserFormValues } from "../../shared/types/components";

export interface UserViewProps {
  user?: User;
  roles?: Role[];
}

export const UserView: React.FC<UserViewProps> = (props) => {
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] =
    React.useState<boolean>(false);

  function handleOpenUpdateUserModalButtonClick(): void {
    setIsUpdateUserModalOpen(true);
  }

  function handleUpdateUserModalCloseAction(): void {
    setIsUpdateUserModalOpen(false);
  }

  function handleUpdateUserFormCancelButtonClick(): void {
    setIsUpdateUserModalOpen(false);
  }

  function handleUpdateUserFormSubmitSuccess(): void {
    setIsUpdateUserModalOpen(false);
  }

  const updateUserFormInitialValues = React.useMemo<
    UpdateUserFormValues | undefined
  >(
    () =>
      props.user !== undefined
        ? {
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            emailAddress: props.user.emailAddress,
            status: props.user.status,
          }
        : undefined,
    [props.user]
  );

  return (
    <>
      <div className="max-w-7xl mx-auto px-4">
        <main className="max-w-5xl mx-auto mt-6">
          {props.user !== undefined && (
            <>
              <div className="flex justify-between items-center">
                <span className="font-semibold text-xl text-gray-700 dark:text-gray-200">{`${props.user.firstName} ${props.user.lastName}`}</span>
                <button
                  type="button"
                  onClick={handleOpenUpdateUserModalButtonClick}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 dark:text-blue-100 bg-blue-100 dark:bg-blue-900 disabled:text-blue-500 dark:disabled:text-blue-400 disabled:bg-blue-50 dark:disabled:bg-blue-900 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-blue-200 dark:hover:bg-blue-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  Edit
                </button>
              </div>
              <div className="mt-3">
                <UserDetails user={props.user} roles={props.roles ?? []} />
              </div>
            </>
          )}
        </main>
      </div>
      <Modal isOpen={isUpdateUserModalOpen} handleCloseAction={handleUpdateUserModalCloseAction}>
        {() =>
          props.user !== undefined && (
            <UpdateUserForm
              userID={props.user.id}
              initialValues={updateUserFormInitialValues}
              handleCancelButtonClick={handleUpdateUserFormCancelButtonClick}
              handleFormSubmitSuccess={handleUpdateUserFormSubmitSuccess}
            />
          )
        }
      </Modal>
    </>
  )
};
