import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getInvoiceList,
  getTenantList,
  getPropertyList,
} from "./Invoices.slice";
import Layout from "./Layout";
import Table from "../../../components/Table/Table";
import Badge from "../../../components/Badge";
import Panel from "../../../components/Panel";
import ComboboxAutocomplete from "../../../components/Form/ComboboxAutocomplete";
import qs from "qs";
import {
  formatUTCDate,
  formatCurrency,
  makeEnumReadable,
} from "../../../util/string";
import { InvoiceStatus, InvoiceSummaries } from '../../../apis/types'
import { useAppDispatch, useAppSelector } from '../../../shared/redux/hooks'
import { getStatementApi } from '../../../apis/apis'
import { pushToast } from '../../../components/Toaster/Toaster.slice'

export interface InvoicesURLParams {
  propertyId: string | undefined
  tenantId: string | undefined
  status: string | undefined
}

const Invoices: React.FC = () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const dispatch = useAppDispatch()
  const [propertyId, setPropertyId] = useState<string | undefined>(searchParams.get('propertyId') ?? undefined)
  const [tenantId, setTenantId] = useState<string | undefined>(searchParams.get('tenantId') ?? undefined)
  const [status, setStatus] = useState<InvoiceStatus>(searchParams.get('status') as InvoiceStatus)

  // TODO To reenable the "Trailing 30-day totals" feature remove this flag here and stop the Panel
  // below from being conditionally rendered based on it
  const isTrailing30DayTotalsDisabled = true
  // TODO Also delete this hardcoded InvoiceSummary and import summary below on line 50
  // TODO Also uncomment the call to getInvoiceSummary() on line 56
  const summary: InvoiceSummaries | undefined = {
    PAID: { number: 0, amount: 0 },
    PAST_DUE: { number: 0, amount: 0 },
    PENDING: { number: 0, amount: 0 },
    PROCESSING: { number: 0, amount: 0 },
    VOID: { number: 0, amount: 0 },
  }
  // TODO Also go to src/features/collections/invoices/Invoices.slice.tsx and delete
  // the hardcoded api response for getInvoiceSummary()

  const navigate = useNavigate()
  // TODO import summary here to reenable "Trailing 30-day totals" feature
  const { invoices, tenants, properties } = useAppSelector((state) => state.invoices)

  useEffect(() => {
    // void dispatch(getInvoiceSummary())
    void dispatch(getTenantList())
    void dispatch(getPropertyList())
  }, [dispatch])

  useEffect(() => {
    void dispatch(
      getInvoiceList({
        page: 0,
        propertyId,
        tenantId,
        status,
      })
    )
    navigate(`/collections/invoices${qs.stringify({ propertyId, tenantId, status }, { addQueryPrefix: true, skipNulls: true })}`, { replace: true })
  }, [propertyId, tenantId, status, dispatch, navigate])

  const handlePaginatorClick = (page: number): void => {
    void dispatch(
      getInvoiceList({
        page,
        propertyId,
        tenantId,
        status,
      })
    )
  }

  const statusSortOrder = ['PAST_DUE', 'BALANCE_CARRY_FORWARD', 'PENDING', 'PROCESSING', 'PAID', 'PAID_EXTERNALLY', 'VOID']

  const statusSortFunction = (a: string, b: string): number => statusSortOrder.indexOf(a) - statusSortOrder.indexOf(b)

  const openStatement = async (statementId: string, type: 'king' | 'utility') => {
    try {
      const statementFile = await getStatementApi(statementId, type)
      const statementFileBlob: Blob = new Blob([statementFile], {
        type: 'application/pdf',
      })
      const statementFileUrl: string = window.URL.createObjectURL(statementFileBlob)
      window.open(statementFileUrl, '_blank')
    } catch (e) {
      await dispatch(
        pushToast({
          type: 'error',
          message: 'Error loading statement',
          description: `${
            (e as XMLHttpRequest).response?.data?.message !== undefined ? `${(e as XMLHttpRequest).response?.data?.message as string}:` : 'Error:'
          } ${(e as { message: string }).message}`,
        })
      )
    }
  }

  return (
    <>
      <Layout title="Invoices">
        {!isTrailing30DayTotalsDisabled && (
          <Panel header="Trailing 30-day totals" className="mb-5">
            <div className="grid grid-flow-col text-center">
              {(Object.keys(summary ?? {}) as Array<keyof InvoiceSummaries>).sort(statusSortFunction).map((status, index) => (
                <div key={`status-${index}`}>
                  <div className="font-medium">
                    <Badge status={status} />
                  </div>
                  <div className="text-2xl">{summary?.[status]?.number}</div>
                  <div className="">{formatCurrency(summary?.[status]?.amount ?? 0)}</div>
                </div>
              ))}
            </div>
          </Panel>
        )}
        <Panel header="Filters" className="mb-5">
          <div className="grid grid-auto-flow grid-cols-3 gap-3">
            {/* <QuickSearch title="Tenant name" initialValue={quickSearchTerm} handleChange={handleQuickSearchChange} /> */}
            <div>
              <label htmlFor="propertyNames" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Property
              </label>
              {properties !== undefined && (
                <ComboboxAutocomplete labelPlural="Properties" value={propertyId ?? ''} handleChange={setPropertyId} data={properties} dropDownZIndex="z-10" />
              )}
            </div>
            <div>
              <label htmlFor="tenantNames" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Tenant
              </label>
              {properties !== undefined && (
                <ComboboxAutocomplete labelPlural="Tenants" value={tenantId ?? ''} handleChange={setTenantId} data={tenants} dropDownZIndex="z-10" />
              )}
            </div>
            <div>
              <label htmlFor="statusType" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Status
              </label>
              {properties !== undefined && (
                <ComboboxAutocomplete
                  labelPlural="Statuses"
                  value={status ?? ''}
                  handleChange={(arg0) => setStatus(arg0 as InvoiceStatus)}
                  data={Object.keys(summary ?? {}).map((status: string) => ({
                    id: status,
                    name: makeEnumReadable(status),
                  }))}
                  dropDownZIndex="z-10"
                />
              )}
            </div>
          </div>
        </Panel>
        {!(invoices == null) && (
          <Table
            colConfig={[
              {
                label: 'Tenant / Property',
                render: (rec) => (
                  <>
                    <span className="font-medium">{rec.tenant.name}</span>
                    <br />
                    <span>{rec.tenant.propertyName}</span>
                  </>
                ),
              },
              {
                label: (
                  <>
                    Account
                    <br />
                    Number
                  </>
                ),
                render: (rec) => (
                  <Link
                    to={`/tenant/tenants/${rec.tenant.id}?serviceAccountNumber=${rec.tenantAccountNumber}&tab=5`}
                    className="text-indigo-500 dark:text-indigo-400"
                  >
                    {rec.tenantAccountNumber}
                  </Link>
                ),
              },
              {
                label: (
                  <>
                    Statement
                    <br />
                    Number
                  </>
                ),
                render: (rec) => rec.number,
              },
              {
                label: (
                  <>
                    Statement
                    <br />
                    Date
                  </>
                ),
                render: (rec) => formatUTCDate(new Date(rec.statementDate)),
              },
              {
                label: (
                  <>
                    Billing
                    <br />
                    Period
                  </>
                ),
                render: (rec) => `${formatUTCDate(new Date(rec.billingStart))} - ${formatUTCDate(new Date(rec.billingEnd))}`,
              },
              {
                label: (
                  <>
                    Due
                    <br />
                    Date
                  </>
                ),
                render: (rec) => formatUTCDate(new Date(rec.due)),
              },
              {
                label: (
                  <>
                    Period
                    <br />
                    Charges
                  </>
                ),
                render: (rec) => formatCurrency(rec.periodChargeAccrual),
              },
              {
                label: (
                  <>
                    Period Due
                    <br />
                    Balance
                  </>
                ),
                render: (rec) => formatCurrency(rec.unpaidInvoiceBalance),
              },
              {
                label: 'DPD When Paid',
                render: (rec) => rec.daysPastDue,
              },
              {
                label: 'Payment Status',
                render: (rec) => <Badge status={rec.paymentStatus} />,
              },
              {
                label: (
                  <>
                    Amount
                    <br />
                    Due
                  </>
                ),
                render: (rec) => formatCurrency(rec.amountDue),
              },
              {
                label: (
                  <>
                    Payment
                    <br />
                    Method
                  </>
                ),
                render: (rec) => {
                  if (rec.paymentMethod === null) {
                    return null
                  }

                  if (rec.paymentMethod.type === 'CHECK') {
                    return 'Check'
                  }
                  if (rec.paymentMethod.providerName !== undefined) {
                    return `${rec.paymentMethod.providerName} ${rec.paymentMethod.last4 !== undefined ? `(••••${rec.paymentMethod.last4})` : ''}`
                  }

                  return null
                },
              },
              {
                label: 'Memo',
                render: (rec) => rec.memo,
              },
              {
                label: 'Invoice Status',
                render: (rec) => <Badge status={rec.status} />,
              },
              {
                label: 'View Statement',
                render: (rec) => (
                  <div className="flex flex-row justify-between">
                    <button
                      type="button"
                      onClick={async () => await openStatement(rec.id, 'king')}
                      className="inline-flex records-center mr-2 px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-indigo-700 dark:text-indigo-300 bg-indigo-100 dark:bg-indigo-900 hover:bg-indigo-200 dark:hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-indigo-400"
                    >
                      King
                    </button>
                    {rec.externalStatementAvailable && (
                      <button
                        type="button"
                        onClick={async () => await openStatement(rec.id, 'utility')}
                        className="inline-flex records-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-indigo-700 dark:text-indigo-300 bg-indigo-100 dark:bg-indigo-900 hover:bg-indigo-200 dark:hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-indigo-400"
                      >
                        Utility
                      </button>
                    )}
                  </div>
                ),
              },
            ]}
            data={invoices}
            handlePaginatorClick={handlePaginatorClick}
          />
        )}
      </Layout>
    </>
  )
}

export default Invoices;
