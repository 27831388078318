import React, { useState, useEffect } from "react";
import Panel from '../../components/Panel'
import Button from '../../components/Form/Button'
import { pushToast } from '../../components/Toaster/Toaster.slice'
import { commitAllocationSubmissionApi, getAllocationReadinessApi } from '../../apis/apis'
import { AllocationReadinessItem, WholeProgramWarnings, AllocationSubmission, ReadinessChecklist } from '../../apis/types'
import VNEMWarning from './components/VNEMWarning'
import { formatUTCDetailedDate } from '../../util/string'
import Modal from '../../components/Modal'
import { useAppDispatch } from '../../shared/redux/hooks'
import classNames from 'classnames'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

interface CaptureVNEMSubmissionProps {
  programId: string
  onCaptureVNEMSubmission: () => void
  setReadinessCheckList: React.Dispatch<React.SetStateAction<ReadinessChecklist | null>>
  onFailureToFetchVNEMSubmission: () => void
}

const baseRowClasses = 'contents [&>*]:border-slate-200 dark:[&>*]:border-slate-600'
const errorRowClasses = 'text-red-500 [&>*]:bg-red-50 [&>*]:border-red-200 dark:text-red-400 dark:[&>*]:bg-red-950/40 dark:[&>*]:border-slate-600'
const unallocatedCreditClasses = 'contents [&>*]:bg-blue-50 [&>*]:border-blue-200 dark:[&>*]:bg-blue-900/50 dark:[&>*]:border-slate-600'

const CaptureVNEMSubmission: React.FC<CaptureVNEMSubmissionProps> = ({
  programId,
  onCaptureVNEMSubmission,
  setReadinessCheckList,
  onFailureToFetchVNEMSubmission,
}) => {
  const [wholeProgramWarnings, setWholeProgramWarnings] = useState<WholeProgramWarnings | null | undefined>(null)
  const [allocationSubmission, setAllocationSubmission] = useState<AllocationSubmission | null | undefined>(null)
  const [allocationReadinessList, setAllocationReadinessList] = useState<AllocationReadinessItem[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [confirmModal, setConfirmModal] = useState<React.ReactElement | boolean>(false)
  const dispatch = useAppDispatch()

  const handleCaptureVNEMSubmission = async (submitted: boolean): Promise<void> => {
    if (submitted) {
      try {
        if (allocationSubmission != null) {
          await commitAllocationSubmissionApi(programId, allocationSubmission)
          await dispatch(
            pushToast({
              type: 'success',
              message: 'VNEM allocations captured successfully',
            })
          )
          setReadinessCheckList((prev) => {
            if (prev == null) return prev
            else
              return {
                ...prev,
                VNEMAllocationCaptured: true,
              }
          })
        } else {
          throw new Error('allocationSubmission cannot be null')
        }
      } catch (e: any) {
        await dispatch(
          pushToast({
            type: 'error',
            message: 'Error capturing VNEM allocations',
            description: `${e.response?.data?.error !== undefined ? `${e.response?.data?.error as string}:` : 'Error:'} ${e.message as string}`,
          })
        )
      }
    }

    onCaptureVNEMSubmission()
  }

  useEffect(() => {
    const fetchAllocationReadinessList = async (): Promise<void> => {
      try {
        const res = await getAllocationReadinessApi(programId)
        setWholeProgramWarnings({
          allocationSum: res.allocationSum,
          doesAllocationSumTo100: res.doesAllocationSumTo100,
          doesActiveAllocationAlreadyExist: res.doesActiveAllocationAlreadyExist,
          activeAllocationSubmissionDate: res.activeAllocationSubmissionDate,
        })
        setAllocationSubmission(res.allocationSubmission)
        setAllocationReadinessList(res.allocations)
        setIsLoading(false)
      } catch (e: any) {
        await dispatch(
          pushToast({
            type: 'error',
            message: 'Error fetching VNEM allocations',
            description: `${e.response?.data?.error !== undefined ? `${e.response?.data?.error as string}:` : 'Error:'} ${e.response.data.message as string}`,
          })
        )
        onFailureToFetchVNEMSubmission()
      }
    }

    fetchAllocationReadinessList().catch(async (e: any) => {
      // Handle any errors that weren't caught inside the async function
      await dispatch(
        pushToast({
          type: 'error',
          message: 'Error fetching VNEM allocations',
          description: `${e.response?.data?.error !== undefined ? `${e.response?.data?.error as string}:` : 'Error:'} ${e.response.data.message as string}`,
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {confirmModal}
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <Panel header="Capture VNEM Submission">
          {allocationReadinessList?.map((item) => {
            const allocation = item.allocation
            const oneBill = item.oneBill
            const readiness = item.readiness

            return (
              <div
                className={classNames('rounded-md p-2 mb-8', {
                  'ring-2 ring-red-500 dark:ring-red-400':
                    !readiness.doesTenantAccountNameMatch ||
                    !readiness.doesUtilityAccountNameMatch ||
                    !readiness.doesServiceAddressMatch ||
                    !readiness.doesUtilityAccountNumberMatch ||
                    !readiness.doesServiceAgreementMatch ||
                    !readiness.doesMeterNumberMatch ||
                    !readiness.doesTariffMatch,
                })}
              >
                <div className="grid grid-cols-3">
                  <div className="p-2">&nbsp;</div>
                  <div className="p-2 font-semibold">Allocation</div>
                  <div className="p-2 font-semibold">OneBill™</div>

                  <div
                    className={classNames(baseRowClasses, {
                      [errorRowClasses]: !readiness.doesTenantAccountNameMatch,
                    })}
                  >
                    <div className="p-2 font-semibold border-b">Tenant Account Name</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{allocation.accountName}</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{oneBill.tenantAccountName}</div>
                  </div>

                  <div
                    className={classNames(baseRowClasses, {
                      [errorRowClasses]: !readiness.doesUtilityAccountNameMatch,
                    })}
                  >
                    <div className="p-2 font-semibold border-b">Utility Account Name</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{allocation.utilityAccountName}</div>
                    <div className="p-2 truncate border-b">{oneBill.utilityAccountName}</div>
                  </div>

                  <div
                    className={classNames(baseRowClasses, {
                      [errorRowClasses]: !readiness.doesUtilityAccountNumberMatch,
                    })}
                  >
                    <div className="p-2 font-semibold border-b">Utility Account Number</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{allocation.utilityAccountNumber}</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{oneBill.utilityAccountNumber}</div>
                  </div>

                  <div
                    className={classNames(baseRowClasses, {
                      [errorRowClasses]: !readiness.doesServiceAddressMatch,
                    })}
                  >
                    <div className="p-2 font-semibold border-b">Service Address</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{allocation.serviceAddress}</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">
                      {oneBill.serviceAddress?.address1}
                      {oneBill.serviceAddress?.address2 ? ` ${oneBill.serviceAddress?.address2}` : ''}{' '}
                      {oneBill.serviceAddress?.city ? `${oneBill.serviceAddress?.city}, ` : ''}
                      {oneBill.serviceAddress?.state} {oneBill.serviceAddress?.postalCode}
                    </div>
                  </div>

                  <div
                    className={classNames(baseRowClasses, {
                      [errorRowClasses]: !readiness.doesServiceAgreementMatch,
                    })}
                  >
                    <div className="p-2 font-semibold border-b">Service Agreement</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{allocation.serviceAgreementId}</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{oneBill.serviceAgreement}</div>
                  </div>

                  <div
                    className={classNames(baseRowClasses, {
                      [errorRowClasses]: !readiness.doesMeterNumberMatch,
                    })}
                  >
                    <div className="p-2 font-semibold border-b">Meter Number</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{allocation.meterNumber}</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{oneBill.meterNumber}</div>
                  </div>

                  <div
                    className={classNames(baseRowClasses, {
                      [errorRowClasses]: !readiness.doesTariffMatch,
                    })}
                  >
                    <div className="p-2 font-semibold border-b">Tariff</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{allocation.tariff}</div>
                    <div className="p-2 text-ellipsis overflow-hidden border-b">{oneBill.tariff}</div>
                  </div>

                  <div className="p-2 font-semibold border-b dark:border-slate-600">Allocation Percentage</div>
                  <div className="p-2 text-ellipsis overflow-hidden border-b dark:border-slate-600">{allocation.allocation}</div>
                  <div className="p-2 border-b dark:border-slate-600">–</div>

                  {allocation.designatedUnallocatedCreditAccount ? (
                    <div className={unallocatedCreditClasses}>
                      <div className="p-2 font-semibold">Unallocated Credit Meter</div>
                      <div className="p-2 text-ellipsis overflow-hidden">
                        <CheckCircleIcon className="w-5 text-blue-900 dark:text-blue-300" />
                      </div>
                      <div className="p-2 font-semibold">&nbsp;</div>
                    </div>
                  ) : null}
                </div>
              </div>
            )
          })}
          <div>
            <VNEMWarning type="warning" text="Unallocated credit meter was auto-selected. Please verify that is matches the submission" />
            {wholeProgramWarnings?.doesActiveAllocationAlreadyExist === true && wholeProgramWarnings.activeAllocationSubmissionDate != null && (
              <div className="mt-2">
                <VNEMWarning
                  type="warning"
                  text={`Proceeding will replace current submission from ${formatUTCDetailedDate(
                    new Date(wholeProgramWarnings.activeAllocationSubmissionDate)
                  )}`}
                />
              </div>
            )}
            {wholeProgramWarnings?.doesAllocationSumTo100 === false && (
              <div className="mt-2">
                <VNEMWarning type="error" text={`Allocations in this submission sum to ${wholeProgramWarnings?.allocationSum}%, not 100%`} />
              </div>
            )}
            <div className="mt-4 flex flex-col items-start">
              {wholeProgramWarnings?.allocationSum != null && (
                <span className="text-sm">
                  <strong>Total Allocation Percentage:</strong> {wholeProgramWarnings?.allocationSum}%
                </span>
              )}
              {allocationSubmission?.submissionDate != null && (
                <span className="text-sm">
                  <strong>Submission Date:</strong> {formatUTCDetailedDate(allocationSubmission.submissionDate)}
                </span>
              )}
            </div>
            <div className="mt-4 font-semibold">Would you like to capture this VNEM allocation submission?</div>
            <div className="mt-2 flex flex-row">
              <Button
                onClick={async () => {
                  try {
                    await new Promise((resolve, reject) => {
                      setConfirmModal(<Modal resolve={resolve} reject={reject} />)
                    })
                    setConfirmModal(false)
                    try {
                      await handleCaptureVNEMSubmission(true)
                    } catch (e) {}
                  } catch (e) {
                    setConfirmModal(false)
                  }
                }}
              >
                Confirm Capture
              </Button>
              <Button
                className="ml-3"
                onClick={async () => {
                  await handleCaptureVNEMSubmission(false)
                }}
                color="slate"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Panel>
      )}
    </>
  )
}

export default CaptureVNEMSubmission;
