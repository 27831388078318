import React, { useEffect, useState, useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import axios from 'axios'
import CoreLayout from './CoreLayout'
import 'tailwindcss/tailwind.css'
import './global.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/800.css'
import { useAuth0 } from '@auth0/auth0-react'
import { getUserPermissions } from './App.slice'
import * as Sentry from '@sentry/react'
import { useAppDispatch } from './shared/redux/hooks'
import TenantSearch from './components/TenantSearch/TenantSearch'

const { VITE_AUTH0_AUDIENCE: AUTH0_AUDIENCE, VITE_AUTH0_SCOPE: AUTH0_SCOPE } = import.meta.env

const App: React.FC = () => {
  const { getAccessTokenSilently, isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const dispatch = useAppDispatch()

  const [showGlobalSearch, setShowGlobalSearch] = useState(false)

  // Listens for the key combination of meta + k to toggle the global search bar
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.metaKey && event.key === 'k') {
      event.preventDefault()
      setShowGlobalSearch((prev) => !prev)
    }
    if (event.key === 'Escape') {
      setShowGlobalSearch(false)
    }
  }, [])

  useEffect(() => {
    const checkAuthSession = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          cacheMode: 'off', // Force token refresh if expired
          detailedResponse: true,
        })

        // If we get here, we have a valid token
        const token = typeof accessToken === 'object' ? accessToken.access_token : accessToken
        localStorage.setItem('accessToken', token)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        // Pass the access token to the reducer in the store to store user permissions in global state
        dispatch(getUserPermissions({ accessToken: token, isAuthenticated }))
      } catch (error) {
        // If token refresh fails, redirect to login
        console.error('Auth session error:', error)
        loginWithRedirect({
          authorizationParams: {
            audience: AUTH0_AUDIENCE,
            scope: AUTH0_SCOPE,
          },
        })
      }
    }

    // Check session every 5 minutes
    if (!isLoading && isAuthenticated) {
      checkAuthSession()
      const intervalId = setInterval(checkAuthSession, 5 * 60 * 1000)
      return () => clearInterval(intervalId)
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          audience: AUTH0_AUDIENCE,
          scope: AUTH0_SCOPE,
        },
      })
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect, dispatch])

  useEffect(() => {
    if (isAuthenticated && user) {
      Sentry.setUser({
        email: user?.email,
      })
      Sentry.setContext('User', {
        email: user?.email,
      })
    }
  }, [isAuthenticated, user])

  // Attach the event listener for the key combination to toggle the global search bar
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  if (isLoading || !isAuthenticated) {
    return <div className="px-8 py-6 text-sm font-medium text-slate-500 dark:text-slate-400 absolute top-0">Loading...</div>
  }

  return (
    <React.Suspense fallback={<div className="px-8 py-6 text-sm font-medium text-slate-500 dark:text-slate-400">Loading...</div>}>
      <CoreLayout>
        {/* Update dark mode toggle button */}

        <Outlet />
        {showGlobalSearch && (
          <div className="fixed inset-0 z-50 flex justify-center bg-black bg-opacity-50 dark:bg-opacity-70" onClick={() => setShowGlobalSearch(false)}>
            <div className="w-[50%] py-20">
              <TenantSearch setShowGlobalSearch={setShowGlobalSearch} autoFocus />
            </div>
          </div>
        )}
      </CoreLayout>
    </React.Suspense>
  )
}

export default App
