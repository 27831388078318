import React, { ChangeEvent } from "react";
import classnames from "classnames";

export interface ControlledQuickSearchProps {
  title: string;
  value: string | undefined;
  placeholder?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ControlledQuickSearch: React.FC<ControlledQuickSearchProps> = (props) => {
  const { title, value = "", placeholder, handleChange } = props;
  return (
    <div className="w-full">
      <label htmlFor="search" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
        {title}
      </label>
      <div className="relative flex items-center">
        <input
          type="text"
          name="search"
          id="search"
          value={value}
          onChange={(e) => handleChange(e)}
          placeholder={placeholder}
          className={classnames(
            'bg-white dark:bg-gray-950 mt-1 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 focus:ring-inset focus:ring-indigo-600 dark:focus:ring-indigo-500 sm:text-sm sm:leading-6',
            {
              'bg-indigo-50 dark:bg-indigo-900/70 border-indigo-300 dark:border-indigo-700 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-indigo-500 dark:focus:border-indigo-400':
                value,
              'focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-indigo-500 dark:focus:border-indigo-400': value !== undefined,
            }
          )}
        />
      </div>
    </div>
  )
};

export default ControlledQuickSearch;
