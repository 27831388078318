import React from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../shared/redux/hooks'
import { getNavConfig } from './navConfig'
import { NavItemComponent } from './NavItemComponent'
import UserInfo from './UserInfo'
import ThemeToggle from './ThemeToggle'

const Nav: React.FC = () => {
  const location = useLocation()
  const { permissions } = useAppSelector((state) => state.app)

  const hasFileExplorerPermission = permissions?.includes('storagedrive:file:read') ?? false
  const navConfig = getNavConfig(hasFileExplorerPermission)

  return (
    <>
      <div className="flex flex-1 flex-col justify-between py-0 pb-2.5">
        <div>
          {navConfig.map((item, index) => (
            <NavItemComponent key={`${item.to}-${index}`} item={item} isBaseRouteActive={location.pathname.includes(item.baseRoute ?? '')} />
          ))}
        </div>
        <ThemeToggle />
      </div>
      <UserInfo />
    </>
  )
}

export default Nav
