import React from 'react'

interface ActionsBadgeProps {
  name: string
  onClick?: () => void
  gDriveExternalLink?: string
}

const ActionsBadge: React.FC<ActionsBadgeProps> = ({ name, onClick, gDriveExternalLink }) => {
  const button = (
    <button
      className="inline-flex items-center px-2.5 py-0.5 mr-2 rounded-md font-medium text-blue-900 dark:text-blue-100 bg-blue-100 dark:bg-blue-900 disabled:text-blue-500 dark:disabled:text-blue-400 disabled:bg-blue-50 dark:disabled:bg-blue-900 disabled:cursor-not-allowed border border-transparent hover:bg-blue-200 dark:hover:bg-blue-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      onClick={onClick}
    >
      <span className="ml-2 mr-2">{name}</span>
    </button>
  )

  return (
    <>
      {gDriveExternalLink != null ? (
        <a href={gDriveExternalLink} target="_blank" rel="noreferrer">
          {button}
        </a>
      ) : (
        button
      )}
    </>
  )
}

export default ActionsBadge
