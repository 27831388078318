import React from "react";

import { usersAPI } from "../../shared/api";
import { useFormik } from "formik";

import { validate as validateEmailAddress } from "email-validator";

import { viewPropsByUserStatus } from "../../shared/view-props/user-status";

import { Switch } from "@headlessui/react";

import classNames from "classnames";

import type { User } from "../../shared/types/models";
import type { UpdateUserFormValues } from "../../shared/types/components";
import type { APIErrors } from "../../../api/errors";
import type { FormikErrors } from "formik";

const { VITE_KING_ENERGY_USERS_EMAIL_DOMAIN: KING_ENERGY_USERS_EMAIL_DOMAIN } =
  import.meta.env;

export interface UpdateUserFormProps {
  userID: string;
  initialValues?: UpdateUserFormValues;
  handleCancelButtonClick?: () => void;
  handleFormSubmittingChange?: (isSubmitting: boolean) => void;
  handleFormSubmitSuccess?: (user: User) => void;
  handleFormSubmitFailure?: (error: unknown) => void;
}

export const UpdateUserForm: React.FC<UpdateUserFormProps> = (props) => {
  const [updateUser] = usersAPI.endpoints.updateUser.useMutation();

  const formik = useFormik<UpdateUserFormValues>({
    initialValues: props.initialValues ?? {
      firstName: "",
      lastName: "",
      emailAddress: `@${KING_ENERGY_USERS_EMAIL_DOMAIN}`,
      status: "active",
    },
    validate: (values) => {
      const errors: FormikErrors<UpdateUserFormValues> = {};

      if (values.firstName.trim().length === 0) {
        errors.firstName = "First name is required";
      }

      if (values.lastName.trim().length === 0) {
        errors.lastName = "Last name is required";
      }

      if (values.emailAddress.trim().length === 0) {
        errors.emailAddress = "Email address is required";
      } else if (!validateEmailAddress(values.emailAddress)) {
        errors.emailAddress = "Email address must be valid";
      }

      return errors;
    },
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });

  async function handleEmailAddressInputValueChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const username = event.target.value;

    await formik.setValues((values) => ({
      ...values,
      emailAddress: `${username}@${getHostname(values.emailAddress)}`,
    }));
  }

  async function handleActiveToggleValueChange(
    isActive: boolean
  ): Promise<void> {
    await formik.setValues((values) => ({
      ...values,
      status: isActive ? "active" : "blocked",
    }));
  }

  function handleCancelButtonClick(): void {
    props.handleCancelButtonClick?.();
  }

  async function handleSubmit(values: UpdateUserFormValues): Promise<void> {
    const result = await updateUser({
      userID: props.userID,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      status: values.status,
    });

    if ("data" in result) {
      const user = result.data;

      props.handleFormSubmitSuccess?.(user);
    } else if ("error" in result) {
      const error = result.error as APIErrors;

      if (
        error.code === "entity-unique-constraint-broken" &&
        error.meta.entity.typeName === "User" &&
        "emailAddress" in error.meta.entity.keys
      ) {
        formik.setErrors({
          emailAddress: "Email address is already used",
        });
      }

      props.handleFormSubmitFailure?.(error);
    }
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full max-w-md p-6 overflow-y-visible text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl"
    >
      <div className="flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Update User</h3>
        <div className="flex items-center">
          <label htmlFor="status" className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {viewPropsByUserStatus[formik.values.status].label}
          </label>
          <Switch
            id="status"
            name="status"
            checked={formik.values.status === 'active'}
            onChange={(isActive) => {
              void handleActiveToggleValueChange(isActive)
            }}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            className={classNames(
              'ml-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring focus:ring-blue-200/50',
              {
                'bg-blue-500': formik.values.status === 'active',
                'bg-gray-100': formik.values.status === 'blocked',
              }
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              className={classNames(
                'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                {
                  'translate-x-5': formik.values.status === 'active',
                  'translate-x-0': formik.values.status === 'blocked',
                }
              )}
            >
              <span
                className={classNames('absolute inset-0 h-full w-full flex items-center justify-center transition-opacity', {
                  'opacity-0 ease-out duration-100': formik.values.status === 'active',
                  'opacity-100 ease-in duration-200': formik.values.status === 'blocked',
                })}
                aria-hidden="true"
              >
                <svg className="h-3 w-3 text-gray-500" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              <span
                className={classNames('absolute inset-0 h-full w-full flex items-center justify-center transition-opacity', {
                  'opacity-100 ease-in duration-200': formik.values.status === 'active',
                  'opacity-0 ease-out duration-100': formik.values.status === 'blocked',
                })}
                aria-hidden="true"
              >
                <svg className="h-3 w-3 text-blue-500" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </div>
      </div>

      <div className="mt-3 grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName" className="text-sm font-medium text-gray-700">
            First Name
          </label>
          <div className="mt-1">
            <input
              id="firstName"
              name="firstName"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              value={formik.values.firstName}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={classNames(
                'mt-1 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                {
                  'border-gray-300 focus:border-blue-300 focus:ring-blue-200/50': !(
                    formik.touched.firstName !== undefined &&
                    formik.touched.firstName &&
                    formik.errors.firstName !== undefined
                  ),
                  'border-red-300 focus:border-red-300 focus:ring-red-200/50':
                    formik.touched.firstName !== undefined && formik.touched.firstName && formik.errors.firstName !== undefined,
                }
              )}
              aria-invalid={formik.touched.firstName !== undefined && formik.touched.firstName && formik.errors.firstName !== undefined ? 'true' : 'false'}
              aria-describedby={
                formik.touched.firstName !== undefined && formik.touched.firstName && formik.errors.firstName !== undefined ? 'first-name-error' : undefined
              }
            />
          </div>
          {formik.touched.firstName !== undefined && formik.touched.firstName && formik.errors.firstName !== undefined && (
            <p className="mt-1 text-sm text-red-500" id="first-name-error">
              {formik.errors.firstName}
            </p>
          )}
        </div>
        <div>
          <label htmlFor="lastName" className="text-sm font-medium text-gray-700">
            Last Name
          </label>
          <div className="mt-1">
            <input
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              value={formik.values.lastName}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={classNames(
                'mt-1 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                {
                  'border-gray-300 focus:border-blue-300 focus:ring-blue-200/50': !(
                    formik.touched.lastName !== undefined &&
                    formik.touched.lastName &&
                    formik.errors.lastName !== undefined
                  ),
                  'border-red-300 focus:border-red-300 focus:ring-red-200/50':
                    formik.touched.lastName !== undefined && formik.touched.lastName && formik.errors.lastName !== undefined,
                }
              )}
              aria-invalid={formik.touched.lastName !== undefined && formik.touched.lastName && formik.errors.lastName !== undefined ? 'true' : 'false'}
              aria-describedby={
                formik.touched.lastName !== undefined && formik.touched.lastName && formik.errors.lastName !== undefined ? 'last-name-error' : undefined
              }
            />
          </div>
          {formik.touched.lastName !== undefined && formik.touched.lastName && formik.errors.lastName !== undefined && (
            <p className="mt-1 text-sm text-red-500" id="last-name-error">
              {formik.errors.lastName}
            </p>
          )}
        </div>
        <div className="col-span-2">
          <label htmlFor="emailAddress" className="text-sm font-medium text-gray-700">
            Email Address
          </label>
          <div className="relative mt-1 flex rounded-md shadow-sm">
            <input
              id="emailAddress"
              name="emailAddress"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              value={getUsername(formik.values.emailAddress)}
              disabled={true}
              onChange={(event) => {
                void handleEmailAddressInputValueChange(event)
              }}
              onBlur={formik.handleBlur}
              className={classNames(
                'block w-full rounded-md border-0 py-1.5 pl-2 pr-[130px] text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                {
                  'border-gray-300 focus:border-blue-300 focus:ring-blue-200/50': !(
                    formik.touched.emailAddress !== undefined &&
                    formik.touched.emailAddress &&
                    formik.errors.emailAddress !== undefined
                  ),
                  'border-red-300 focus:border-red-300 focus:ring-red-200/50':
                    formik.touched.emailAddress !== undefined && formik.touched.emailAddress && formik.errors.emailAddress !== undefined,
                }
              )}
              aria-invalid={
                formik.touched.emailAddress !== undefined && formik.touched.emailAddress && formik.errors.emailAddress !== undefined ? 'true' : 'false'
              }
              aria-describedby={
                formik.touched.emailAddress !== undefined && formik.touched.emailAddress && formik.errors.emailAddress !== undefined
                  ? 'email-address-error'
                  : undefined
              }
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 dark:text-gray-400 sm:text-sm" id="price-currency">
                @{getHostname(formik.values.emailAddress)}
              </span>
            </div>
          </div>
          {formik.touched.emailAddress !== undefined && formik.touched.emailAddress && formik.errors.emailAddress !== undefined && (
            <p className="mt-1 text-sm text-red-500" id="email-address-error">
              {formik.errors.emailAddress}
            </p>
          )}
        </div>
      </div>
      <div className="mt-5 flex justify-end">
        <div>
          <button
            type="button"
            disabled={formik.isSubmitting}
            onClick={handleCancelButtonClick}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 disabled:text-gray-500 dark:disabled:text-gray-400 disabled:bg-gray-50 dark:disabled:bg-gray-800 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 dark:text-blue-100 bg-blue-100 dark:bg-blue-900 disabled:text-blue-500 dark:disabled:text-blue-400 disabled:bg-blue-50 dark:disabled:bg-blue-950 disabled:cursor-not-allowed border border-transparent rounded-md hover:bg-blue-200 dark:hover:bg-blue-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
            {formik.isSubmitting && (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Save
          </button>
        </div>
      </div>
    </form>
  )
};

export default UpdateUserForm;

function getUsername(emailAddress: string): string {
  return emailAddress.slice(0, emailAddress.lastIndexOf("@"));
}

function getHostname(emailAddress: string): string {
  return emailAddress.slice(emailAddress.lastIndexOf("@") + 1);
}
