import React, { Fragment } from "react";

import { Listbox, Transition } from "@headlessui/react";

import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/24/solid";

export interface SelectProps {
  selected: { label: string; value: string };
  options: Array<{ label: string; value: string }>;
  disabled?: boolean;
  onChange: (selection: { label: string; value: string }) => void;
}

export const Select: React.FC<SelectProps> = (props) => {
  return (
    <Listbox value={props.selected} disabled={props.disabled} onChange={props.onChange}>
      <div className="relative">
        <Listbox.Button className="mt-1 relative w-full py-2 pl-3 pr-10 text-left text-sm bg-white dark:bg-slate-800 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-orange-500">
          {props.selected.label}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon className="w-5 h-5 text-slate-400 dark:text-slate-500" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 w-full py-1 px-0 mt-1 overflow-auto text-sm bg-white dark:bg-slate-800 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
            {props.options.map((option, idx) => (
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  `${active ? 'text-orange-900 bg-orange-100 dark:text-orange-100 dark:bg-orange-900/30' : 'text-slate-900 dark:text-slate-100'}
                  cursor-default select-none relative py-2 pl-10 pr-4`
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>{option.label}</span>
                    {selected ? (
                      <span
                        className={`${active ? 'text-orange-600 dark:text-orange-400' : 'text-orange-600 dark:text-orange-400'}
                        absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
};
