import IconSearch from './icons/icon-search'
import IconCash from './icons/icon-cash'
import IconTenants from './icons/icon-tenants'
import IconPrograms from './icons/icon-programs'
import IconUsers from './icons/icon-users'
import { DocumentCurrencyDollarIcon, FolderIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'

export interface NavItem {
  label: string | React.ReactNode
  to: string
  baseRoute?: string
  icon: React.ReactNode
  children?: Array<{
    label: string
    to: string
  }>
  exact?: boolean
}

export const getNavConfig = (hasFileExplorer: boolean): NavItem[] => [
  {
    label: (
      <>
        Quick Search <div className="inline-block ml-1 text-xs font-normal">{navigator.platform.includes('Win') ? '⊞K' : '⌘K'}</div>
      </>
    ),
    to: '/',
    exact: true,
    icon: <IconSearch />,
  },
  {
    label: 'Invoices',
    to: '/collections/invoices',
    icon: <DocumentCurrencyDollarIcon className="h-6 w-6" />,
  },
  {
    label: 'Payments',
    to: '/collections/payments',
    icon: <IconCash />,
  },
  ...(hasFileExplorer
    ? [
        {
          label: 'File Explorer',
          to: '/file-explorer',
          icon: <FolderIcon className="h-6 w-6" />,
        },
      ]
    : []),
  {
    label: 'Programs',
    to: '/program/programs',
    icon: <IconPrograms />,
  },
  {
    label: 'Tenants',
    to: '/tenant/tenants',
    icon: <IconTenants />,
  },
  {
    label: 'Onboarding',
    to: '/tenant/onboarding',
    icon: <RocketLaunchIcon className="h-6 w-6" />,
  },
  {
    label: 'Users',
    to: '/users',
    icon: <IconUsers />,
  },
]
